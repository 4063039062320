import React from "react";
import Grid from "@mui/material/Grid";
import {
  Stack,
  Typography,
  Link,
  TextField,
  Button,
  Divider,
  Box,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <motion.div
      className="box"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.4, delay: 0 }}
    >
      <Grid container sx={{ bgcolor: "#010b1d", color: "#bdbdbd" }}>
        <Grid
          container
          maxWidth="xl"
          justifyContent="center"
          alignItems="top"
          sx={{ bgcolor: "#010b1d", marginX: "auto", paddingY: 4, paddingX: 2 }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" color="#EAEDFF">
              Optima Jobs
            </Typography>
            <Stack direction="row" spacing={1}>
              <Link
                href="https://www.facebook.com/profile.php?id=100074294913946"
                color="inherit"
                target="_blank"
              >
                <FacebookIcon fontSize="large" />
              </Link>
              <Link
                href="https://youtube.com/channel/UCTays168VKZoKzseIpAeg-w"
                color="inherit"
                target="_blank"
              >
                <YouTubeIcon fontSize="large" />
              </Link>
              <Link
                href="http://instagram.com/optima.jobs"
                color="inherit"
                target="_blank"
              >
                <InstagramIcon fontSize="large" />
              </Link>
              <Link
                href="https://www.linkedin.com/in/optima-jobs-83b136166"
                color="inherit"
                target="_blank"
              >
                <LinkedInIcon fontSize="large" />
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" color="secondary.main">
              Important Links
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <Link href="/" underline="hover" color="inherit">
                Home
              </Link>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Link href="/jobs" underline="hover" color="inherit">
                Jobs
              </Link>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Link href="/about" underline="hover" color="inherit">
                About
              </Link>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Link href="/services" underline="hover" color="inherit">
                Services
              </Link>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Link href="/contact" underline="hover" color="inherit">
                Contact
              </Link>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" color="secondary.main">
              Get In Touch...
            </Typography>
            <Typography marginTop={1}>Address</Typography>
            <Typography variant="body2" color="dimGrey">
              Kolhapur
            </Typography>
            <Typography marginTop={1}>Email</Typography>
            <Typography variant="body2" color="dimGrey">
              hr@optimajobs.ininfo@optimajobs.in
            </Typography>
            <Typography marginTop={1}>Phone Number</Typography>
            <Typography variant="body2" color="dimGrey">
              9923968262, 9960480227, 7559272207
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginY: 2, borderColor: "dimGrey" }} />
          </Grid>
          <Grid item>
            <Typography>
              Copyright © 2022 - Optima Jobs. Designed & Developed by AMRUJ
              GLOBAL SOULTIONS
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default Footer;
