import { useState } from "react";

//import material
import {
  Card,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

//project components
import InformationText from "./InformationText";
import GeneralInfoForm from "./GeneralInfoForm";

const GeneralInfo = (props) => {
  const { candidate, onSave, onCancel } = props;
  const [openForm, setOpenForm] = useState(false);

  const handleCancel = () => {
    setOpenForm(false);
  };

  const handleSave = () => {
    setOpenForm(false);
    onSave();
  };

  return (
    <>
      <Card elevation={3}>
        <Grid
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 0.5,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "#ffffff",
                }}
              >
                Personal Information
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ padding: 3 }}>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="Full Name" data={candidate.name} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="Email" data={candidate.email} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Date of Birth"
              data={new Date(candidate.dateOfBirth).toLocaleDateString("hi-IN")}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Mobile Number"
              data={
                candidate.primaryMobileNumber +
                ", " +
                candidate.alternateMobileNumber
              }
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="Gender" data={candidate.gender} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Maritial Status"
              data={candidate.marritalStatus}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 1 }} />
        <Grid container spacing={4} sx={{ padding: 3 }}>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Address"
              data={candidate.addressLine1 + ", " + candidate.addressLine2}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <InformationText dataOf="City" data={candidate.city} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="Taluka" data={candidate.taluka} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="District" data={candidate.district} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="State" data={candidate.state} />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 1 }} />
        <Grid container spacing={4} sx={{ padding: 3 }}>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Experience"
              data={
                candidate.experienceMonths === 0 &&
                candidate.experienceYears === 0
                  ? "Fresher"
                  : candidate.experienceYears +
                    " Years & " +
                    candidate.experienceMonths +
                    " Months"
              }
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText dataOf="Skills" data={candidate.skills} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Current Salary / Month"
              data={candidate.existingSalary}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InformationText
              dataOf="Current Post"
              data={candidate.currentPost}
            />
          </Grid>
        </Grid>
      </Card>
      <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="md">
        <DialogContent>
          <GeneralInfoForm
            onSave={handleSave}
            onCancel={handleCancel}
            candidate={candidate}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GeneralInfo;
