import * as React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//material ui import
import {
  IconButton,
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import GroupIcon from "@mui/icons-material/Group";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";

import CompanyLogo from "../../assets/images/HeaderLogo.jpeg";

export default function MenuDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const drawerContent = (anchor) => (
    <Box
      sx={{
        width: 250,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ marginTop: 3, marginBottom: 2 }}>
        <img src={CompanyLogo} alt="company-logo" width="200px" />
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      {role === 1 || role === 0 ? (
        <Box sx={{ width: "100%", padding: 1 }}>
          <Box>
            <ListItemButton
              onClick={() => {
                navigate("/");
                toggleDrawer(true);
              }}
              sx={{
                borderLeft: 4,
                borderColor:
                  window.location.pathname === "/"
                    ? theme.palette.primary.main
                    : theme.palette.white.main,
              }}
              color="primary.main"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/jobs");
                toggleDrawer(true);
              }}
              sx={{
                borderLeft: 4,
                borderColor:
                  window.location.pathname === "/jobs" ||
                  window.location.pathname === "/jobdetails"
                    ? theme.palette.primary.main
                    : theme.palette.white.main,
              }}
            >
              <ListItemIcon>
                <WorkspacesIcon />
              </ListItemIcon>
              <ListItemText primary={"Jobs"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/services");
                toggleDrawer(true);
              }}
              sx={{
                borderLeft: 4,
                borderColor:
                  window.location.pathname === "/services"
                    ? theme.palette.primary.main
                    : theme.palette.white.main,
              }}
            >
              <ListItemIcon>
                <HomeRepairServiceIcon />
              </ListItemIcon>
              <ListItemText primary={"Services"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/about");
                toggleDrawer(true);
              }}
              sx={{
                borderLeft: 4,
                borderColor:
                  window.location.pathname === "/about"
                    ? theme.palette.primary.main
                    : theme.palette.white.main,
              }}
            >
              <ListItemIcon>
                <EmojiTransportationIcon />
              </ListItemIcon>
              <ListItemText primary={"About"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/contact");
                toggleDrawer(true);
              }}
              sx={{
                borderLeft: 4,
                borderColor:
                  window.location.pathname === "/contact"
                    ? theme.palette.primary.main
                    : theme.palette.white.main,
              }}
            >
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText primary={"Contact"} />
            </ListItemButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Divider sx={{ marginBottom: 1 }} />
          <Box>
            <ListItemButton
              onClick={() => {
                navigate("/admin/candidates");
                toggleDrawer(true);
              }}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={"Candidates"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/admin/employers");
                toggleDrawer(true);
              }}
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Employers"} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                navigate("/admin/jobposts");
                toggleDrawer(true);
              }}
            >
              <ListItemIcon>
                <HomeRepairServiceIcon />
              </ListItemIcon>
              <ListItemText primary={"Job Posts"} />
            </ListItemButton>

            {/* <ListItemButton
              onClick={() => {
                navigate("/admin/settings");
                toggleDrawer(true);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItemButton> */}

            <ListItemButton
              onClick={() => {
                navigate("/admin/interviews");
                toggleDrawer(true);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Interviews"} />
            </ListItemButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <Button onClick={toggleDrawer(true)} variant="contained" size="small">
          <MenuIcon />
        </Button>
        <Drawer anchor={"left"} open={openDrawer} onClose={toggleDrawer(false)}>
          {drawerContent("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
