import React from "react";
import {
  Button,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import PageHeader from "../../../assets/images/page-header.png";
import AboutImage from "../../../assets/images/about.jpg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";

const Contact = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${PageHeader})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          align: "center",
          height: "300px",
          width: "100%",
          padding: "100px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "#ffffff" }}>
            Contact
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginY: 5 }}
      >
        <Grid container spacing={5} maxWidth="md" sx={{ padding: 2 }}>
          <Grid item xs={12} md={6}>
            <Stack spacing={4}>
              <Stack direction="row" spacing={2}>
                <LocationOnOutlinedIcon
                  sx={{ color: "#0d42ff", fontSize: "30px" }}
                />
                <Stack>
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Location:
                  </Typography>
                  <Typography>Kolhapur</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <EmailOutlinedIcon
                  sx={{ color: "#0d42ff", fontSize: "28px" }}
                />
                <Stack>
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Email:
                  </Typography>
                  <Typography>hr@optimajobs.in</Typography>
                  <Typography>info@optimajobs.in</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <SmartphoneOutlinedIcon
                  sx={{ color: "#0d42ff", fontSize: "25px" }}
                />
                <Stack>
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    Call:
                  </Typography>
                  <Typography>+91-9923968262</Typography>
                  <Typography>+91-9960480227</Typography>
                  <Typography>+91-7559272207</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Get in touch with us...
            </Typography>
            <Stack direction="column" spacing={2}>
              <TextField name="name" label="Your Name" fullWidth />
              <TextField name="email" label="Your Email" fullWidth />
              <TextField name="subject" label="Subject" fullWidth />
              <TextField
                name="message"
                label="Message"
                fullWidth
                multiline
                minRows="3"
                maxRows="10"
              />
              <Button variant="contained" sx={{ background: "#0d42ff" }}>
                Send Message
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container sx={{ padding: "100px" }}>
        <Grid item xs={12}>
          <Stack spacing={4}>
            <Stack direction="row" spacing={2}>
              <LocationOnOutlinedIcon
                sx={{ color: "#0d42ff", fontSize: "40px" }}
              />
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  Location:
                </Typography>
                <Typography>A108 Adam Street, New York, NY 535022</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <EmailOutlinedIcon sx={{ color: "#0d42ff", fontSize: "40px" }} />
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  Email:
                </Typography>
                <Typography>info@example.com</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <SmartphoneOutlinedIcon
                sx={{ color: "#0d42ff", fontSize: "40px" }}
              />
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  Call:
                </Typography>
                <Typography>+91-1234567890</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item sm={8}>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField name="name" label="Your Name" size="small" fullWidth />
            </Grid>
            <Grid item sm={6}>
              <TextField
                name="email"
                label="Your Email"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                name="subject"
                label="Subject"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                name="message"
                label="Message"
                fullWidth
                multiline
                minRows="3"
                maxRows="10"
              />
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{ background: "#0d42ff" }}>
                Send Message
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default Contact;
