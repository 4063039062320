import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//material ui
import {
  Grid,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
  Pagination,
  TablePagination,
  Fab,
  Stack,
  TextField,
  InputAdornment,
  Alert,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from "@mui/material";

import PlaceIcon from "@mui/icons-material/Place";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PeopleIcon from "@mui/icons-material/People";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import CurrencyRupeeSharpIcon from "@mui/icons-material/CurrencyRupeeSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useTheme } from "@mui/material/styles";

//project components
import config from "../../../config";
import Job1 from "../../../assets/images/job1.png";

//third party
import axios from "axios";
import parse from "html-react-parser";
import JobPostForm from "../../admin/job-posts/JobPostForm";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Box } from "@mui/system";
import InterviewForm from "../../admin/job-posts/InterviewForm";

const JobPostDetails = () => {
  const navigate = useNavigate();
  const { userId, userName, role, profileId, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const [jobPost, setJobPost] = useState();
  const [selectedApplication, setSelectedApplication] = useState();
  const [selectedInterview, setSelectedInterview] = useState({
    id: 0,
    jobPostId: 0,
    description: "description",
    dateTime: new Date(),
    venue: "venue",
    status: 0,
  });

  const [openInterViewForm, setOpenInterViewForm] = useState(false);
  const [openJobPostForm, setOpenJobPostForm] = useState(false);

  const [openAddApplicationConfirmation, setOpenAddApplicationConfirmation] =
    useState(false);
  const [
    openRemoveApplicationConfirmation,
    setOpenRemoveApplicationConfirmation,
  ] = useState(false);
  const [openMarkPlacedConfirmation, setOpenMarkPlacedConfirmation] =
    useState(false);
  const [openRemovePlacedConfirmation, setOpenRemovePlacedConfirmation] =
    useState(false);
  const [
    openRemoveInterviewApplicationConfirmation,
    setOpenRemoveInterviewApplicationConfirmation,
  ] = useState(false);
  const [openInterviewDeleteConfirmation, setOpenInterviewDeleteConfirmation] =
    useState(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Success");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error");

  useEffect(() => {
    getJobPost();
  }, []);
  const backFunction = () => {
    setOpenJobPostForm(true);
    navigate("/admin/jobposts");
  };

  const getJobPost = () => {
    if (searchParams.get("ref") === null) {
      return;
    }
    let id = searchParams.get("ref");
    axios
      .get(config.serverURL + "/api/JobPosts/" + id)
      .then((response) => {
        setJobPost(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  return (
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
        {jobPost != undefined ? (
          <Grid container spacing={3} sx={{ padding: 4 }}>
            <Grid item md={3} xs={12}>
              <Typography
                sx={{
                  fontWeight: "550",
                  fontSize: "1.4em",
                  color: "#001F6A",
                }}
              >
                {jobPost.employerName}
              </Typography>

              <Divider sx={{ marginY: 2 }} />
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <PlaceIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.location}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <BusinessCenterIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.jobTypeDisplay}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <PeopleIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.noOfVaccancy} Vacanies
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <SchoolSharpIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.qualification}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <CurrencyRupeeSharpIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  Salary : {jobPost.minSalary} - {jobPost.maxSalary} PM
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <WorkHistoryIcon fontSize="small" color="primary" />
                {jobPost.maxExprienceRequired > 0 ? (
                  <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                    Experience : {jobPost.minExprienceRequired} -{" "}
                    {jobPost.maxExprienceRequired} Years
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                    Freshers are allowed
                  </Typography>
                )}
              </Stack>

              <Divider sx={{ mb: 2 }} />
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Edit">
                    <Fab
                      size="small"
                      color="primary"
                      onClick={() => {
                        setOpenJobPostForm(true);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Fab size="small" color="error" onClick={() => {}}>
                      <DeleteIcon fontSize="small" />
                    </Fab>
                  </Tooltip>

                  {jobPost.status === 0 ? (
                    <Tooltip title="Publish Job Post">
                      <Fab
                        size="small"
                        color="primary"
                        onClick={() => {
                          axios
                            .post(
                              config.serverURL +
                                "/api/JobPosts/UpdateStatus?jobPostId=" +
                                jobPost.id +
                                "&status=1"
                            )
                            .then((response) => {
                              getJobPost();
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        <CheckCircleIcon fontSize="small" />
                      </Fab>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Unpublish Job Post">
                      <Fab
                        size="small"
                        color="primary"
                        onClick={() => {
                          axios
                            .post(
                              config.serverURL +
                                "/api/JobPosts/UpdateStatus?jobPostId=" +
                                jobPost.id +
                                "&status=0"
                            )
                            .then((response) => {
                              getJobPost();
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        <UnpublishedIcon fontSize="small" />
                      </Fab>
                    </Tooltip>
                  )}
                </Stack>
                <Tooltip title="Go Back">
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      let prePage = searchParams.get("prePage");
                      navigate(prePage);
                    }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </Stack>
              <Divider sx={{ marginY: 2 }} />
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: "550",
                  fontSize: "1.4em",
                  color: "#001F6A",
                }}
              >
                {jobPost.title}
              </Typography>
              <Typography sx={{ color: "#757575" }}>
                {parse(jobPost.jobDescription)}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Divider></Divider>
        <Grid sx={{ margin: 2 }}>
          <Typography variant="h6">Job Applications Received</Typography>
          {jobPost?.jobApplications.map((application, index) => {
            return (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  padding: 1,
                  marginTop: 1,
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor:
                    application.status === 2
                      ? theme.palette.success.light
                      : theme.palette.grey[200],
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ marginRight: 2 }}>
                      {index + 1}.
                    </Typography>
                    <Link
                      to={"/profile?ref=" + application.candidateId}
                      underline="hover"
                    >
                      {application.candidateName}
                    </Link>
                    <Typography sx={{ marginLeft: 2 }}>
                      ({application.candidatePhoneNo})
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Select For Interview">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setSelectedApplication(application);
                          setOpenAddApplicationConfirmation(true);
                        }}
                      >
                        <PlaylistAddCheckIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Remove Application">
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => {
                          setSelectedApplication(application);
                          setOpenRemoveApplicationConfirmation(true);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </Grid>
        <Divider />
        <Grid sx={{ margin: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: 2 }}
          >
            <Typography variant="h6">Interviews</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedInterview({
                  id: 0,
                  jobPostId: jobPost.id,
                  description: "",
                  dateTime: new Date().toISOString().split("T")[0],
                  venue: "",
                  status: 0,
                });
                setOpenInterViewForm(true);
              }}
            >
              Add New Interview
            </Button>
          </Stack>
          {jobPost?.interviews.map((interview, index) => {
            return (
              <Accordion
                key={index}
                sx={{ backgroundColor: theme.palette.grey[200] }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ fontWeight: 500 }} color="primary.main">
                    Interview {index + 1}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Interview Date:
                        </Typography>
                        <Typography>
                          {
                            new Date(interview.dateTime.toString() + "Z")
                              .toISOString()
                              .split("T")[0]
                          }
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Typography sx={{ fontWeight: 600 }}>Venue:</Typography>
                        <Typography>{interview.venue}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 600 }}>
                        Description:
                      </Typography>
                      <Typography color="primary.main">
                        {interview.description}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider sx={{ marginY: 2 }} />
                  <Typography sx={{ fontWeight: 600 }}>
                    Selected Applications for Interview.
                  </Typography>
                  <Stack direction="row">
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      {interview.jobApplicationInterviews?.map(
                        (application, index) => {
                          return (
                            <Grid item xs={12} lg={6} key={index}>
                              <Card
                                sx={{
                                  paddingX: 2,
                                  paddingY: 1,
                                  marginRight: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  direction: "row",
                                  justifyContent: "space-between",
                                  backgroundColor:
                                    application.jobApplication.status === 2
                                      ? theme.palette.success.light
                                      : theme.palette.grey[200],
                                }}
                                variant="outlined"
                              >
                                <Typography sx={{ marginRight: 3 }}>
                                  {application.jobApplication.candidateName}
                                </Typography>
                                <Box>
                                  {application.jobApplication.status === 2 ? (
                                    <Tooltip title="Remove placed status">
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ marginRight: 2 }}
                                        onClick={() => {
                                          setSelectedApplication(
                                            application.jobApplication
                                          );
                                          setOpenRemovePlacedConfirmation(true);
                                        }}
                                      >
                                        <UnpublishedIcon />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Mark as placed">
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ marginRight: 2 }}
                                        onClick={() => {
                                          setSelectedApplication(
                                            application.jobApplication
                                          );
                                          setOpenMarkPlacedConfirmation(true);
                                        }}
                                      >
                                        <CheckCircleIcon />
                                      </Button>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Remove Application">
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        setSelectedApplication(application);
                                        setOpenRemoveInterviewApplicationConfirmation(
                                          true
                                        );
                                      }}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Card>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Stack>
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    onClick={() => {
                      setSelectedInterview({
                        id: interview.id,
                        jobPostId: interview.jobPostId,
                        description: interview.description,
                        dateTime: new Date(interview.dateTime.toString() + "Z")
                          .toISOString()
                          .split("T")[0],
                        venue: interview.venue,
                        status: 0,
                      });
                      setOpenInterViewForm(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    onClick={() => {
                      if (interview.jobApplicationInterviews.length > 0) {
                        alert(
                          "Cannot delete the interview as it has applications. Remove all applications first."
                        );
                      } else {
                        setSelectedInterview({
                          id: interview.id,
                          jobPostId: interview.jobPostId,
                          description: interview.description,
                          dateTime: new Date(
                            interview.dateTime.toString() + "Z"
                          )
                            .toISOString()
                            .split("T")[0],
                          venue: interview.venue,
                          status: 0,
                        });
                        setOpenInterviewDeleteConfirmation(true);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </AccordionActions>
              </Accordion>
            );
          })}
        </Grid>
      </Card>

      {/* jobpost form dialog */}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openJobPostForm}
        onClose={() => {
          setOpenJobPostForm(false);
        }}
      >
        <DialogContent>
          <JobPostForm
            jobPost={jobPost}
            onSave={() => {
              getJobPost();
              setOpenJobPostForm(false);
            }}
            onCancel={() => {
              setOpenJobPostForm(false);
            }}
          />
        </DialogContent>
      </Dialog>

      {/* interview form dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openInterViewForm}
        onClose={() => {
          setOpenInterViewForm(false);
        }}
      >
        <DialogContent>
          <InterviewForm
            interview={selectedInterview}
            onSave={() => {
              getJobPost();
              setOpenInterViewForm(false);
            }}
            onCancel={() => {
              setOpenInterViewForm(false);
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Add application to interview confirmation dialog */}
      <Dialog
        fullWidth
        open={openAddApplicationConfirmation}
        keepMounted
        onClose={() => {
          setOpenAddApplicationConfirmation(false);
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Add Below Job Application To The Interview
            </Typography>
            <IconButton
              onClick={() => {
                setOpenAddApplicationConfirmation(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          Candidate:
          <Typography sx={{ fontWeight: 500 }} color="secondary.dark">
            {selectedApplication?.candidateName}
          </Typography>
          {jobPost?.interviews.length > 0 ? (
            <>
              {jobPost?.interviews.map((interview, index) => {
                return (
                  <Button
                    variant="outlined"
                    key={index}
                    fullWidth
                    sx={{ marginTop: 2 }}
                    onClick={() => {
                      axios
                        .post(
                          config.serverURL + "/api/JobApplicationInterview",
                          {
                            id: 0,
                            jobApplicationId: selectedApplication?.id,
                            interviewId: interview.id,
                          }
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            axios
                              .put(
                                config.serverURL +
                                  "/api/JobApplications/" +
                                  selectedApplication.id +
                                  "/1"
                              )
                              .then((response) => {
                                if (response.status === 200) {
                                  setSuccessMsg(
                                    "Application selected for interview successfully!"
                                  );
                                  setSuccessOpen(true);
                                  getJobPost();
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                                setErrorOpen(true);
                              });
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setErrorOpen(true);
                        });
                      setOpenAddApplicationConfirmation(false);
                    }}
                  >
                    Interview {index + 1}
                  </Button>
                );
              })}
            </>
          ) : (
            <Typography sx={{ marginY: 3 }}>
              There are no interveiews scheduled. Please add atleast one
              interview
            </Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Remove application from job post dialog */}
      <Dialog
        open={openRemoveApplicationConfirmation}
        keepMounted
        onClose={() => {
          setOpenRemoveApplicationConfirmation(false);
        }}
      >
        <DialogTitle>{"Remove application"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove{" "}
            <b>
              {selectedApplication?.candidateName}
              's
            </b>{" "}
            application from the job post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .delete(
                  config.serverURL +
                    "/api/JobApplications/" +
                    selectedApplication?.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    setSuccessMsg(
                      "Application successfully removed from the job post!"
                    );
                    setSuccessOpen(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                });
              setOpenRemoveApplicationConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRemoveApplicationConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove application from interview dialog */}
      <Dialog
        open={openRemoveInterviewApplicationConfirmation}
        keepMounted
        onClose={() => {
          setOpenRemoveInterviewApplicationConfirmation(false);
        }}
      >
        <DialogTitle>{"Remove application"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove{" "}
            <b>
              {selectedApplication?.jobApplication?.candidateName}
              's
            </b>{" "}
            application from the interview?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .delete(
                  config.serverURL +
                    "/api/JobApplicationInterview/" +
                    selectedApplication.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    setSuccessMsg(
                      "Successfully removed application from the interview"
                    );
                    setSuccessOpen(true);
                    getJobPost();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                });
              setOpenRemoveInterviewApplicationConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRemoveInterviewApplicationConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mark as placed confirmation dialog */}
      <Dialog
        open={openMarkPlacedConfirmation}
        keepMounted
        onClose={() => {
          setOpenMarkPlacedConfirmation(false);
        }}
      >
        <DialogTitle>{"Mark as Placed"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to mark{" "}
            <b>{selectedApplication?.jobApplication?.candidateName}</b> as
            placed for this job post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .put(
                  config.serverURL +
                    "/api/JobApplications/" +
                    selectedApplication?.id +
                    "/2"
                )
                .then((response) => {
                  if (response.status === 200) {
                    getJobPost();
                    setSuccessMsg(
                      "Job application successfully marked as placed!"
                    );
                    setSuccessOpen(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                });
              setOpenMarkPlacedConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenMarkPlacedConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove placed confirmation dialog */}
      <Dialog
        open={openRemovePlacedConfirmation}
        keepMounted
        onClose={() => {
          setOpenRemovePlacedConfirmation(false);
        }}
      >
        <DialogTitle>{"Remove placed status"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove placed status for{" "}
            <b>{selectedApplication?.jobApplication?.candidateName}</b> from
            this job post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .put(
                  config.serverURL +
                    "/api/JobApplications/" +
                    selectedApplication?.id +
                    "/1"
                )
                .then((response) => {
                  if (response.status === 200) {
                    getJobPost();
                    setSuccessMsg("Successfully removed placed status!");
                    setSuccessOpen(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                });
              setOpenRemovePlacedConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRemovePlacedConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete interview confirmation dialog */}
      <Dialog
        open={openInterviewDeleteConfirmation}
        keepMounted
        onClose={() => {
          setOpenInterviewDeleteConfirmation(false);
        }}
      >
        <DialogTitle>{"Delete Interview"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the selected interview?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .delete(
                  config.serverURL + "/api/Interviews/" + selectedInterview?.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    getJobPost();
                    setSuccessMsg("Successfully deleted interview!");
                    setSuccessOpen(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                });
              setOpenInterviewDeleteConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenInterviewDeleteConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "#009933",
            color: theme.palette.white.main,
          }}
          variant="filled"
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default JobPostDetails;
