import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const WebsiteLayout = () => {
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  return (
    <Grid>
      <Grid container>
        <Header />
      </Grid>
      <Grid sx={{ marginTop: 8 }}>
        <Outlet />
      </Grid>
      {role < 4 && (
        <Grid container sx={{ display: { xs: "none", md: "block" } }}>
          <Footer />
        </Grid>
      )}
      {role === 4 && (
        <Grid container sx={{ bgcolor: "#010b1d", color: "#bdbdbd" }}>
          <Grid item xs={12}>
            <Typography textAlign="center" sx={{ margin: 2 }}>
              Copyright © 2022 - Optima Jobs. Designed & Developed by AMRUJ
              GLOBAL SOULTIONS
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WebsiteLayout;
