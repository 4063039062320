import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  Button,
  Card,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container } from "@mui/system";
import { useFormik } from "formik";
import { setJobPostFilterData } from "../../../../store/generalSlice";

const SearchComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      jobType: 0,
      position: "",
      location: "",
      skill: "",
      minSalary: 0,
      maxSalary: 0,
      status: 0,
      skip: 0,
      take: 50,
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(setJobPostFilterData(values));
      navigate(
        "/jobs?skill=" +
          formik.values.skill +
          "&location=" +
          formik.values.location
      );
    },
  });

  return (
    <Grid theme={theme}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            <TextField
              variant="standard"
              placeholder="Search by skills, job title"
              name="skill"
              fullWidth
              value={formik.values.skill}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <TextField
              variant="standard"
              placeholder="Search by location"
              name="location"
              fullWidth
              value={formik.values.location}
              onChange={formik.handleChange}
            ></TextField>
          </Grid>
          <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              // sx={{
              //   borderRadius: 2,
              //   bgcolor: "#2A569F",
              //   color: "#ffffff",
              //   "&:hover": {
              //     backgroundColor: "#4a7dcf",
              //   },
              // }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default SearchComponent;
