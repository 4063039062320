import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";

import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

//project imports
import config from "../../../config";
import { setUser } from "../../../store/userSlice";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const ChangePassword = (props) => {
  const { userId, onCancel } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
        accessToken: "",
      })
    );
    navigate("/auth");
  };

  const formik = useFormik({
    initialValues: {
      userId: userId,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      newPassword: Yup.string("Enter your password")
        .max(50, "Must be 50 characters or less ")
        .min(6, "Password should be of minimum 6 characters length")
        .required("Password is required"),
      confirmNewPassword: Yup.string()
        .required("Please confirm your password")
        .max(50, "Must be 50 characters or less ")
        .when("newPassword", {
          is: (newPassword) =>
            newPassword && newPassword.length > 0 ? true : false,
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Password doesn't match"
          ),
        }),
    }),

    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(config.serverURL + "/api/Authentication/ChangePassword", values)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              alert("Password Changed Successfully!, Login Again.");
              handleLogout();
            } else {
              alert(response.data.message);
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    },
  });
  return (
    <>
      <Grid container>
        <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
          <AccountBalanceIcon />

          <Typography
            color={theme.palette.primary.dark}
            variant="h6"
            textAlign="center"
            sx={{ marginLeft: 2 }}
          >
            Change Password
          </Typography>
        </Stack>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl variant="outlined" fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel htmlFor="outlined-adornment-oldPassword">
                Old Password
              </InputLabel>
              <OutlinedInput
                name="oldPassword"
                id="outlined-adornment-oldPassword"
                type={showOldPassword ? "text" : "password"}
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
                label="Old Password"
              />
              {formik.touched.oldPassword && formik.errors.oldPassword && (
                <FormHelperText error id="outlined-adornment-oldPassword">
                  {formik.errors.oldPassword}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel htmlFor="outlined-adornment-newPassword">
                New Password
              </InputLabel>
              <OutlinedInput
                name="newPassword"
                id="outlined-adornment-newPassword"
                type={showNewPassword ? "text" : "password"}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                label="New Password"
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <FormHelperText error id="outlined-adornment-newPassword">
                  {formik.errors.newPassword}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel htmlFor="outlined-adornment-confirmNewPassword">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                name="confirmNewPassword"
                id="outlined-adornment-confirmNewPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                label="Confirm New Password"
              />
              {formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword && (
                  <FormHelperText
                    error
                    id="outlined-adornment-confirmNewPassword"
                  >
                    {formik.errors.confirmNewPassword}
                  </FormHelperText>
                )}
            </FormControl>

            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;
