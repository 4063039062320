import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PageHeader from "../../../assets/images/page-header.png";
import AboutImage from "../../../assets/images/about.jpg";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DoneIcon from "@mui/icons-material/Done";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Services = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${PageHeader})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          align: "center",
          height: "300px",
          width: "100%",
          padding: "100px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "#ffffff" }}>
            Services
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginY: 5 }}
      >
        <Grid container spacing={5} maxWidth="xl" sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4" color="primary">
                Executive Search
              </Typography>
              <Typography>
                We are specialized recruitment agency which helps companies to
                fulfil their requirements regarding manpower at executive
                levels.We have expertise in placing candidates in every sector
                from fresher’s to top level management.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4" color="primary">
                HR System
              </Typography>
              <Typography>
                To help small & medium scale organizations in implementing
                following HR practices
              </Typography>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Prepare Hierarchy</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">
                  Prepare Roles & Responsibilities
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Prepare KRA/KPI </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">
                  Prepare strong review system & Format to get max. output for
                  employee{" "}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">
                  Prepare recruitment process & formats{" "}
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4" color="primary">
                Legal Compliance
              </Typography>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">
                  Employee Provident Fund{" "}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">
                  Employee State Insurance
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Professional Tax</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4" color="primary">
                Employee Assessment
              </Typography>
              <Typography>
                Generally companies doesn’t go for any Employee assessment for
                Existing Employees. As it is very essential part to have the
                assessment of them to know on field problems & Suggestions, we
                do 121 assessment with the employees. This service provided by
                Optima Jobs have given very effective & efficient results. It is
                useful to get following results.
              </Typography>
              <Stack direction="row" spacing={1}>
                <Typography>1. </Typography>
                <Typography align="justify">
                  To measure the performance{" "}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>2. </Typography>
                <Typography align="justify">
                  To know the exact problems if any
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>3. </Typography>
                <Typography align="justify">
                  To get suggestions & Views from Employees at the time of
                  Increment
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>4. </Typography>
                <Typography align="justify">
                  To help to Implement HR Systems.
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h4" color="primary">
                Trainings
              </Typography>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Soft skill Training </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Motivational Training</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Team Building Training</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <DoneIcon fontSize="small" sx={{ color: "#0d42ff" }} />
                <Typography align="justify">Technical Training</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Services;
