import axios from "axios";
const BASE_URL = "http://backend.optimajobs.in";
//const BASE_URL = "https://localhost:44313";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  headers: { ContentType: "application/json" },
  withCredentials: true,
});
