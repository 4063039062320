import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

//maerial ui compoents
import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Alert,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";

//project components
import defaultAvatar from "../../../../assets/images/default-avatar.webp";
import config from "../../../../config";
import { setUser } from "../../../../store/userSlice";
import ChangePassword from "../../../common/auth/ChangePassword";

//third party
import axios from "axios";

const SidePanel = (props) => {
  const { candidate, onSave, setSelectedPage } = props;
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [resumeFile, setResumeFile] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false);
  const [photo, setPhoto] = useState(undefined);
  const [photoURL, setPhotoURL] = useState(undefined);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleListItemClick = (event, index) => {
    if (index <= 1) {
      setSelectedIndex(index);
      setSelectedPage(index);
    } else if (index === 2) {
      setOpenChangePassword(true);
    } else if (index === 3) {
      setOpenDeleteConfirmation(true);
    } else if (index === 4) {
      setOpenRestoreConfirmation(true);
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
        accessToken: "",
      })
    );
    navigate("/");
  };

  useEffect(() => {
    if (candidate.photo != null) {
      setPhotoURL(`${config.serverURL}/images/${candidate.photo}`);
    }
  }, [candidate]);

  useEffect(() => {
    if (photo === undefined) {
      return;
    }
    const formData = new FormData();
    formData.append("file", photo, candidate.id + "-profile.jpg");
    axios
      .post(
        config.serverURL + "/api/Candidates/uploadPhoto/" + candidate.id,
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          onSave();
          setPhoto(undefined);
          setPhotoURL(undefined);
        }
      })
      .catch((error) => {
        console.log(error);
        setUploading(false);
      });
  }, [photo]);

  return (
    <Card elevation={3} sx={{ padding: 2, borderRadius: "6px" }}>
      <Stack alignItems="center" spacing={2}>
        <Stack alignItems="center">
          {candidate.photo != null ? (
            <img src={photoURL} alt="User" width="150px" />
          ) : (
            <img src={defaultAvatar} alt="User" width="150px" />
          )}

          <label htmlFor="btn-upload-photo">
            <input
              id="btn-upload-photo"
              name="btn-upload-1"
              style={{ display: "none" }}
              type="file"
              multiple={false}
              accept="application/jpg"
              onChange={(e) => {
                setPhoto(e.target.files[0]);
              }}
            />
            <Button component="span" size="small">
              Change Photo
            </Button>
          </label>
        </Stack>
        <Box>
          <Typography
            variant="h5"
            color="primary"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {candidate.name}
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center", color: "gray" }}>
            {candidate.city}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel
            value={candidate?.profileCompletionValue}
            color="secondary"
          />
          <Typography variant="subtitle2" textAlign="center">
            Profile
          </Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Stack spacing={2}>
          <Stack direction="row" spacing={1.5}>
            <PhoneIcon fontSize="small" sx={{ color: "#2A569F" }} />
            <Typography variant="body1">
              {candidate.primaryMobileNumber}, {candidate.alternateMobileNumber}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <EmailIcon fontSize="small" sx={{ color: "#2A569F" }} />
            <Typography variant="body1">{candidate.email}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack spacing={2}>
        <label htmlFor="btn-upload-1">
          <input
            id="btn-upload-1"
            name="btn-upload-1"
            style={{ display: "none" }}
            type="file"
            multiple={false}
            accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={(e) => {
              setResumeFile(e.target.files[0]);
            }}
          />
          <Button variant="outlined" component="span" fullWidth>
            {candidate.isResumeUploaded === false
              ? "Choose File"
              : "Change File"}
          </Button>
        </label>
        <LoadingButton
          fullWidth
          disabled={resumeFile === undefined}
          color="primary"
          loading={uploading}
          loadingPosition="end"
          endIcon={<FileUploadRoundedIcon />}
          variant="contained"
          onClick={() => {
            setUploading(true);
            const formData = new FormData();
            let fileExtension = "";
            if (resumeFile.type === "application/pdf") {
              fileExtension = ".pdf";
            } else if (
              resumeFile.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
              fileExtension = ".docx";
            } else {
              setUploading(false);
              setResumeFile(undefined);
              alert("Only PDF and WORD files are allowed");
              return;
            }
            formData.append(
              "file",
              resumeFile,
              candidate.id + "-resume" + fileExtension
            );

            axios
              .post(
                config.serverURL +
                  "/api/Candidates/uploadResume/" +
                  candidate.id,
                formData
              )
              .then((response) => {
                if (response.status === 200) {
                  setUploading(false);
                  setResumeFile(undefined);
                  onSave();
                }
              })
              .catch((error) => {
                console.log(error);
                setUploading(false);
              });
          }}
        >
          Upload Resume
        </LoadingButton>
        {candidate.isResumeUploaded ? (
          <LoadingButton
            fullWidth
            disabled={resumeFile != undefined}
            color="primary"
            loading={downloading}
            loadingPosition="end"
            endIcon={<FileDownloadRoundedIcon />}
            variant="contained"
            onClick={() => {
              setDownloading(true);
              axios
                .get(
                  config.serverURL +
                    "/api/Candidates/DownloadResume?fileName=" +
                    candidate.resumeFileName,
                  { responseType: "blob" }
                )
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    candidate.firstName +
                      " " +
                      candidate.lastName +
                      "-" +
                      candidate.resumeFileName
                  );

                  // Append to html link element page
                  document.body.appendChild(link);

                  // Start download
                  link.click();

                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                  setDownloading(false);
                })
                .catch((error) => {
                  alert(error);
                  setDownloading(false);
                });
            }}
          >
            Download Resume
          </LoadingButton>
        ) : null}
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack>
        <List>
          <ListItemButton
            sx={{
              padding: 1,
              borderLeft: "solid",
              borderLeftWidth: 4,
              borderLeftColor:
                selectedIndex === 0
                  ? theme.palette.primary.main
                  : theme.palette.white.main,
            }}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <AccountCircleOutlinedIcon sx={{ color: "#2A569F" }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>

          <ListItemButton
            sx={{
              padding: 1,
              borderLeft: "solid",
              borderLeftWidth: 4,
              borderLeftColor:
                selectedIndex === 1
                  ? theme.palette.primary.main
                  : theme.palette.white.main,
            }}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <CheckBoxOutlinedIcon sx={{ color: "#2A569F" }} />
            </ListItemIcon>
            <ListItemText primary="My Applications" />
          </ListItemButton>

          <Divider sx={{ my: 2 }} />

          <ListItemButton
            sx={{
              padding: 1,
            }}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon>
              <LockOpenIcon sx={{ color: "#2A569F" }} />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItemButton>
          {role === 4 && (
            <>
              {candidate.isDeleted == true ? (
                <ListItemButton
                  sx={{
                    padding: 1,
                  }}
                  onClick={(event) => handleListItemClick(event, 4)}
                >
                  <ListItemIcon sx={{ color: "#2A569F" }}>
                    <SettingsBackupRestoreIcon />
                  </ListItemIcon>
                  <ListItemText primary="Restore Account" />
                </ListItemButton>
              ) : (
                <ListItemButton
                  sx={{
                    padding: 1,
                  }}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemIcon sx={{ color: "#2A569F" }}>
                    <DeleteOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete Account" />
                </ListItemButton>
              )}
            </>
          )}

          <Divider sx={{ mt: 2 }} />
        </List>
      </Stack>

      {/* Delete dialog */}
      <Dialog
        open={openDeleteConfirmation}
        keepMounted
        onClose={() => {
          setOpenDeleteConfirmation(false);
        }}
      >
        <DialogTitle>{"Delete Account"}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this account?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .delete(
                  config.serverURL +
                    "/api/Candidates/SoftDelete/" +
                    candidate.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    handleLogout();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setOpenDeleteConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDeleteConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Restore dialog */}
      <Dialog
        open={openRestoreConfirmation}
        keepMounted
        onClose={() => {
          setOpenRestoreConfirmation(false);
        }}
      >
        <DialogTitle>{"Restore Account"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to restore this account?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              axios
                .post(
                  config.serverURL + "/api/Candidates/Restore/" + candidate.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    handleLogout();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setOpenRestoreConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRestoreConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Change Password dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openChangePassword}
        onClose={() => {
          setOpenChangePassword(false);
        }}
      >
        <DialogContent>
          <ChangePassword
            userId={userId}
            onCancel={() => {
              setOpenChangePassword(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default SidePanel;
