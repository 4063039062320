import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

//material ui imports
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  InputAdornment,
} from "@mui/material";
import { Box, Container } from "@mui/system";

//Third party imports
import axios from "axios";
import { useFormik } from "formik";

//project imports
import config from "../../../config";
import JobPost from "../../../components/job-post/JobPost";
import { setJobPostFilterData } from "../../../store/generalSlice";

const Jobposts = () => {
  const pageSize = 50;
  const [jobPosts, setJobPosts] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobPostFilterData } = useSelector((state) => state.generalReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      companyName: jobPostFilterData.companyName,
      jobType: jobPostFilterData.jobType,
      position: jobPostFilterData.position,
      location: jobPostFilterData.location,
      skill: jobPostFilterData.skill,
      minSalary: jobPostFilterData.minSalary,
      maxSalary: jobPostFilterData.maxSalary,
      minExperience: jobPostFilterData.minExperience,
      maxExperience: jobPostFilterData.maxExperience,
      status: jobPostFilterData.status,
      skip: jobPostFilterData.skip,
      take: jobPostFilterData.take,
    },
    onSubmit: (values) => {
      dispatch(setJobPostFilterData(values));
      getJobPosts(values, 0);
    },
  });

  const getJobPosts = (filterData, skip) => {
    let minSalary = filterData.minSalary;
    let maxSalary = filterData.maxSalary;
    if (filterData.minSalary == "") {
      //formik.setFieldValue("minSalary", 0);
      minSalary = 0;
    }
    if (filterData.maxSalary == "") {
      //formik.setFieldValue("maxSalary", 0);
      maxSalary = 0;
    }
    let minExperience = filterData.minExperience;
    let maxExperience = filterData.maxExperience;
    if (
      filterData.minExperience === "" ||
      filterData.minExperience === undefined
    ) {
      //formik.setFieldValue("minSalary", 0);
      minExperience = 0;
    }
    if (
      filterData.maxExperience === "" ||
      filterData.minExperience === undefined
    ) {
      //formik.setFieldValue("maxSalary", 0);
      maxExperience = 0;
    }

    let tempFilterData = {
      ...filterData,
      skip: skip,
      minSalary: minSalary,
      maxSalary: maxSalary,
      minExperience: minExperience,
      maxExperience: maxExperience,
    };
    axios
      .post(config.serverURL + "/api/JobPosts/Search", tempFilterData)
      .then((response) => {
        if (response.status === 200) {
          setJobPosts(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    formik.setFieldValue("skill", searchParams.get("skill"));
    formik.setFieldValue("location", searchParams.get("location"));
  }, []);

  useEffect(() => {
    getJobPosts(formik.values, 0);
  }, [formik.values]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          container
          spacing={3}
          maxWidth="xl"
          sx={{ marginY: 3, paddingX: 2 }}
        >
          <Grid item lg={3} xl={3} md={3} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Card variant="outlined">
                <CardHeader title="Filters"></CardHeader>
                <Divider />
                <CardContent>
                  <Stack spacing={3}>
                    <TextField
                      name="skill"
                      label="Skills or Title"
                      fullWidth
                      value={formik.values.skill}
                      onChange={formik.handleChange}
                    ></TextField>
                    <TextField
                      name="location"
                      label="Location"
                      fullWidth
                      value={formik.values.location}
                      onChange={formik.handleChange}
                    ></TextField>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth>
                      <InputLabel>Job Type</InputLabel>
                      <Select
                        name="jobType"
                        label="Job Type"
                        onChange={formik.handleChange}
                        value={formik.values.jobType}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem value={1}>Part Time</MenuItem>
                        <MenuItem value={2}>Full Time</MenuItem>
                        <MenuItem value={3}>Contract Basis</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography>Monthly Salary</Typography>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        name="minSalary"
                        label="Min."
                        fullWidth
                        value={formik.values.minSalary}
                        onChange={formik.handleChange}
                      ></TextField>
                      <TextField
                        type="number"
                        name="maxSalary"
                        label="Max."
                        fullWidth
                        value={formik.values.maxSalary}
                        onChange={formik.handleChange}
                      ></TextField>
                    </Stack>
                    <Typography>Experience Required</Typography>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        name="minExperience"
                        label="Min."
                        fullWidth
                        value={formik.values.minExperience}
                        onChange={formik.handleChange}
                      ></TextField>
                      <TextField
                        type="number"
                        name="maxExperience"
                        label="Max."
                        fullWidth
                        value={formik.values.maxExperience}
                        onChange={formik.handleChange}
                      ></TextField>
                    </Stack>
                  </Stack>
                </CardContent>
                <Divider />
                <CardActions sx={{ paddingY: 3, justifyContent: "center" }}>
                  {/* <Button variant="contained" type="submit">
                    Apply
                  </Button> */}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      let tempFilterData = {
                        companyName: "",
                        jobType: 0,
                        position: "",
                        location: "",
                        skill: "",
                        minSalary: 0,
                        maxSalary: 0,
                        minExperience: 0,
                        maxExperience: 0,
                        status: 0,
                        skip: 0,
                        take: pageSize,
                      };
                      formik.setValues(tempFilterData);
                      dispatch(setJobPostFilterData(tempFilterData));
                      formik.handleSubmit();
                    }}
                  >
                    Clear
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
          <Grid item lg={9} xl={9} md={9} sm={12} xs={12}>
            <Card
              variant="outlined"
              sx={{
                padding: 2,
                marginBottom: 2,
                // display: "flex",
                // direction: "row",
                // justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                  <Typography variant="h5" color="primary">
                    Job Posts
                  </Typography>
                </Grid>
                <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    justifyContent="end"
                  >
                    <Typography>Total: {jobPosts?.count}</Typography>
                    {jobPosts != undefined ? (
                      <Pagination
                        count={Math.ceil(jobPosts.count / pageSize)}
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        color="primary"
                        onChange={(event, page) => {
                          getJobPosts(jobPostFilterData, (page - 1) * pageSize);
                        }}
                      />
                    ) : null}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            {jobPosts?.jobPosts.map((row) => (
              <Box key={row.id} sx={{ marginBottom: 2 }}>
                <JobPost jobPost={row} type={0} />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Jobposts;
