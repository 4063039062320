import { useEffect } from "react";

//import material ui

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/theme";
//import theme from "./";

//routing
import Routes from "./routes";
import { Grid } from "@mui/material";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </>
  );
}

export default App;
