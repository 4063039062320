import { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

//project imports
import config from "../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const InterviewForm = (props) => {
  const { onSave, onCancel, interview } = props;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: interview,
    validationSchema: Yup.object({
      description: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      dateTime: Yup.date("Must be valid date").required("Required"),
      venue: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),

    onSubmit: (values) => {
      if (interview.id != 0) {
        axios
          .put(config.serverURL + "/api/Interviews", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .post(config.serverURL + "/api/Interviews", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Grid container>
        {interview.id === 0 ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />

            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Interview
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Interview Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="dateTime"
                  type="date"
                  label="Date *"
                  onChange={formik.handleChange}
                  value={formik.values.dateTime}
                  fullWidth
                  error={
                    formik.touched.dateTime && Boolean(formik.errors.dateTime)
                  }
                  helperText={formik.touched.dateTime && formik.errors.dateTime}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="venue"
                  label="Venue *"
                  onChange={formik.handleChange}
                  value={formik.values.venue}
                  fullWidth
                  error={formik.touched.venue && Boolean(formik.errors.venue)}
                  helperText={formik.touched.venue && formik.errors.venue}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Description *"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  fullWidth
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default InterviewForm;
