import { Stack, Typography } from "@mui/material";
import React from "react";

const InformationText = (props) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {props.dataOf}
      </Typography>
      <Typography variant="body2">{props.data}</Typography>
    </Stack>
  );
};

export default InformationText;
