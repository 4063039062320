import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { axiosAuth } from "../api";
import useRefreshToken from "./useRefreshToken";

const useAxiosAuth = () => {
  const refresh = useRefreshToken();

  const { userId, userName, role, displayRole, accessToken } = useSelector(
    (state) => state.userReducer
  );

  useEffect(() => {
    const requestIntercept = axiosAuth.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosAuth.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh(accessToken);
          prevRequest.headers["Authorization"] = `Brearer ${newAccessToken}`;
          return axiosAuth(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosAuth.interceptors.request.eject(requestIntercept);
      axiosAuth.interceptors.response.eject(responseIntercept);
    };
  }, [refresh, accessToken]); //add auth
  return axiosAuth;
};

export default useAxiosAuth;
