import {
  Card,
  Grid,
  Pagination,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import axios from "axios";
import { useNavigate } from "react-router";
import config from "../../../config";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

const Interviews = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pageSize = 10;
  const [interviewDetails, setInterviewDetails] = useState([]);
  const getInterviews = (skip) => {
    axios
      .get(
        config.serverURL +
          "/api/Interviews/GetInterviews?status=0&skip=" +
          skip +
          "&take=" +
          pageSize
      )
      .then((response) => {
        setInterviewDetails(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getInterviews(0);
  }, []);
  return (
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
        <Grid
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="white.main">
              Interviews Details
            </Typography>
          </Grid>
        </Grid>
        {interviewDetails != undefined ? (
          <>
            <Pagination
              count={Math.ceil(interviewDetails.count / pageSize)}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              color="primary"
              sx={{ marginTop: 2 }}
              onChange={(event, page) => {
                getInterviews((page - 1) * pageSize);
              }}
            />
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {interviewDetails.interviews?.map((row, index) => (
                <Grid key={row.id} item sm={6}>
                  <Card
                    variant="outlined"
                    onClick={() => {
                      navigate(
                        "/admin/jobpost?ref=" +
                          row.jobPost.id +
                          "&prePage=/admin/interviews"
                      );
                    }}
                    sx={{
                      minHeight: "130px",
                      padding: 2,
                      display: "flex",
                      direction: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: theme.palette.secondary.light,
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow:
                          "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" textAlign="left">
                          {row.jobPost.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {row.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <CalendarMonthIcon
                          color="primary"
                          fontSize="small"
                          sx={{
                            marginRight: 1,
                            color: theme.palette.secondary.dark,
                          }}
                        />
                        <Typography variant="body2">
                          {new Date(row.dateTime).toLocaleDateString("hi-IN")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <PlaceIcon
                          color="primary"
                          fontSize="small"
                          sx={{
                            marginRight: 1,
                            color: theme.palette.secondary.dark,
                          }}
                        />
                        <Typography variant="body2">{row.venue}</Typography>
                      </Grid>
                      {/* <Grid
                        item
                        lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 500,
                            color: theme.palette.grey[600],
                          }}
                        >
                          Status:
                        </Typography>
                        <Typography variant="body2">{row.status}</Typography>
                      </Grid> */}
                      {/* <Grid
                        item
                        lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 500,
                            color: theme.palette.grey[600],
                          }}
                        >
                          Job Applications:
                        </Typography>
                        <Typography variant="body2">
                          {interviewDetails.jobApplicationInterviews.length}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", marginY: 5 }}>
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" textAlign="center">
                Loading Employers...
              </Typography>
              <CircularProgress />
            </Stack>
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default Interviews;
