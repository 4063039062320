import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

//import material ui
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
  Pagination,
  TablePagination,
  Fab,
  Stack,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import CloseIcon from "@mui/icons-material/Close";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

//project components
import config from "../../../config";

//third party
import axios from "axios";
import { Box } from "@mui/system";
import EmployerForm from "./EmployerForm";

const Employers = () => {
  const navigate = useNavigate();
  const pageSize = 100;

  const [checked, setChecked] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false);
  const [employers, setEmployers] = useState();
  const [deletedEmployers, setDeletedEmployers] = useState();
  const [selectedEmployer, setSelectedEmployer] = useState();
  const [openEmployerForm, setOpenEmployerForm] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getEmployers = (skip) => {
    if (checked) {
      axios
        .get(
          config.serverURL +
            "/api/Employers/GetDeleted-Employer?skip=0&take=" +
            pageSize
        )
        .then((response) => {
          setEmployers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          config.serverURL +
            "/api/Employers/GetPeginated?search=" +
            searchText +
            "&skip=" +
            skip +
            "&take=" +
            pageSize
        )
        .then((response) => {
          setEmployers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    axios
      .delete(
        config.serverURL + "/api/Employers/SoftDelete/" + selectedEmployer.id
      )
      .then((response) => {
        if (response.status === 200) {
          getEmployers(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDelete(false);
  };

  const handleRestore = () => {
    axios
      .put(config.serverURL + "/api/Employers/Restore/" + selectedEmployer.id)
      .then((response) => {
        if (response.status === 200) {
          getEmployers(0);
          setOpenRestoreConfirmation(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDelete(false);
  };

  useEffect(() => {
    getEmployers(0);
  }, [checked, searchText]);

  return (
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
        <Grid
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="white.main">
              Employers
            </Typography>
            <Grid>
              <Tooltip title="Add New">
                <IconButton
                  color="white"
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    setSelectedEmployer({
                      id: 0,
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      email: "",
                      dateOfBirth: new Date().toISOString().split("T")[0],
                      phoneNumber: "",
                      role: 2,
                      password: "123456",
                      name: "",
                      addressLine1: "",
                      addressLine2: "",
                      city: "",
                      taluka: "",
                      district: "",
                      state: "Maharashtra",
                      pinCode: "",
                      website: "",
                    });
                    setOpenEmployerForm(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {employers != undefined ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingX: 2,
                    paddingTop: 2,
                  }}
                >
                  <TextField
                    size="small"
                    disabled={checked}
                    fullWidth
                    placeholder="search by name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      endAdornment: searchText != "" && (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setSearchText("");
                              getEmployers(0);
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  ></TextField>
                  {/* <Button
                    onClick={() => {
                      getEmployers(0);
                    }}
                    variant="contained"
                    sx={{
                      marginLeft: 1,
                      paddingX: 3,
                    }}
                  >
                    Search
                  </Button> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 2,
                    paddingTop: 2,
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>Total: {employers.count}</Typography>
                    <Pagination
                      count={Math.ceil(employers.count / pageSize)}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                      color="primary"
                      onChange={(event, page) => {
                        getEmployers((page - 1) * pageSize);
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Box sx={{ margin: 1, padding: 1 }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Show deleted employers"
              />
            </FormGroup>
          </Box>
          <Grid item xs={12}>
            {employers != undefined ? (
              <Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                  }}
                >
                  {employers.employers.map((employer, index) => {
                    return (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{
                          margin: 1,
                          padding: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography>
                            <b>Name:</b> {employer.name}
                          </Typography>
                          <Typography>
                            <b>Email:</b> {employer.email}
                          </Typography>
                          <Typography>
                            <b>Phone No.:</b> {employer.phoneNumber}
                          </Typography>
                          <Typography>
                            <b>City:</b> {employer.city}
                          </Typography>
                          <Typography>
                            <b>Website:</b> {employer.webiste}
                          </Typography>
                        </Stack>
                        <Stack
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {/* <Tooltip title="View Details">
                            <IconButton
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                navigate(
                                  "/admin/employer-details?id=" + employer.id
                                );
                              }}
                            >
                              <ArticleIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="Edit">
                            <IconButton
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                let tempEmployer = {
                                  id: employer.id,
                                  firstName: employer.firstName,
                                  middleName: employer.middleName,
                                  lastName: employer.lastName,
                                  email: employer.email,
                                  dateOfBirth: new Date()
                                    .toISOString()
                                    .split("T")[0],
                                  phoneNumber: employer.phoneNumber,
                                  role: 2,
                                  password: "123456",
                                  name: employer.name,
                                  addressLine1: employer.addressLine1,
                                  addressLine2: employer.addressLine2,
                                  city: employer.city,
                                  taluka: employer.taluka,
                                  district: employer.district,
                                  state: employer.state,
                                  pinCode: employer.pinCode,
                                  website: employer.website,
                                };
                                setSelectedEmployer(tempEmployer);
                                setOpenEmployerForm(true);
                              }}
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          {employer.isDeleted === true ? (
                            <Tooltip title="Restore">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  let tempEmployer = {
                                    id: employer.id,
                                    firstName: employer.firstName,
                                    middleName: employer.middleName,
                                    lastName: employer.lastName,
                                    email: employer.email,
                                    dateOfBirth: new Date()
                                      .toISOString()
                                      .split("T")[0],
                                    phoneNumber: employer.phoneNumber,
                                    role: 2,
                                    password: "123456",
                                    name: employer.name,
                                    addressLine1: employer.addressLine1,
                                    addressLine2: employer.addressLine2,
                                    city: employer.city,
                                    taluka: employer.taluka,
                                    district: employer.district,
                                    state: employer.state,
                                    pinCode: employer.pinCode,
                                    website: employer.website,
                                  };
                                  setSelectedEmployer(tempEmployer);
                                  setOpenRestoreConfirmation(true);
                                }}
                              >
                                <SettingsBackupRestoreIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  let tempEmployer = {
                                    id: employer.id,
                                    firstName: employer.firstName,
                                    middleName: employer.middleName,
                                    lastName: employer.lastName,
                                    email: employer.email,
                                    dateOfBirth: new Date()
                                      .toISOString()
                                      .split("T")[0],
                                    phoneNumber: employer.phoneNumber,
                                    role: 2,
                                    password: "123456",
                                    name: employer.name,
                                    addressLine1: employer.addressLine1,
                                    addressLine2: employer.addressLine2,
                                    city: employer.city,
                                    taluka: employer.taluka,
                                    district: employer.district,
                                    state: employer.state,
                                    pinCode: employer.pinCode,
                                    website: employer.website,
                                  };
                                  setSelectedEmployer(tempEmployer);
                                  setOpenDelete(true);
                                }}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Typography>{employer.number}</Typography>
                        </Stack>
                      </Card>
                    );
                  })}
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <TableContainer sx={{ padding: 2 }}>
                    <Table
                      sx={{
                        borderWidth: 1,
                        border: "solid",
                        borderColor: "#f2f2f2",
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#e6eeff" }}>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell align="left">Employer Name</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Phone No.</TableCell>
                          <TableCell align="left">City</TableCell>
                          <TableCell align="left">Website</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employers.employers?.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.number}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell align="left">{row.city}</TableCell>
                            <TableCell align="left">{row.website}</TableCell>
                            <TableCell align="right">
                            <Tooltip title="Edit">
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    navigate(
                                      "/admin/employer-details?ref=" + row.id
                                       );
                                  }}
                                >
                                  <ArticleIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    let employer = {
                                      id: row.id,
                                      firstName: row.firstName,
                                      middleName: row.middleName,
                                      lastName: row.lastName,
                                      email: row.email,
                                      dateOfBirth: new Date()
                                        .toISOString()
                                        .split("T")[0],
                                      phoneNumber: row.phoneNumber,
                                      role: 2,
                                      password: "123456",
                                      name: row.name,
                                      addressLine1: row.addressLine1,
                                      addressLine2: row.addressLine2,
                                      city: row.city,
                                      taluka: row.taluka,
                                      district: row.district,
                                      state: row.state,
                                      pinCode: row.pinCode,
                                      website: row.website,
                                    };
                                    setSelectedEmployer(employer);
                                    setOpenEmployerForm(true);
                                  }}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              {row.isDeleted ? (
                                <Tooltip title="Restore">
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      let tempEmployer = {
                                        id: row.id,
                                        firstName: row.firstName,
                                        middleName: row.middleName,
                                        lastName: row.lastName,
                                        email: row.email,
                                        dateOfBirth: new Date()
                                          .toISOString()
                                          .split("T")[0],
                                        phoneNumber: row.phoneNumber,
                                        role: 2,
                                        password: "123456",
                                        name: row.name,
                                        addressLine1: row.addressLine1,
                                        addressLine2: row.addressLine2,
                                        city: row.city,
                                        taluka: row.taluka,
                                        district: row.district,
                                        state: row.state,
                                        pinCode: row.pinCode,
                                        website: row.website,
                                      };
                                      setSelectedEmployer(tempEmployer);
                                      setOpenRestoreConfirmation(true);
                                    }}
                                  >
                                    <SettingsBackupRestoreIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Delete">
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      let tempEmployer = {
                                        id: row.id,
                                        firstName: row.firstName,
                                        middleName: row.middleName,
                                        lastName: row.lastName,
                                        email: row.email,
                                        dateOfBirth: new Date()
                                          .toISOString()
                                          .split("T")[0],
                                        phoneNumber: row.phoneNumber,
                                        role: 2,
                                        password: "123456",
                                        name: row.name,
                                        addressLine1: row.addressLine1,
                                        addressLine2: row.addressLine2,
                                        city: row.city,
                                        taluka: row.taluka,
                                        district: row.district,
                                        state: row.state,
                                        pinCode: row.pinCode,
                                        website: row.website,
                                      };
                                      setSelectedEmployer(tempEmployer);
                                      setOpenDelete(true);
                                    }}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ display: "flex", justifyContent: "center", marginY: 5 }}
              >
                <Stack
                  direction="column"
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5" textAlign="center">
                    Loading Employers...
                  </Typography>
                  <CircularProgress />
                </Stack>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            {employers != undefined ? (
              <Box
                sx={{
                  paddingX: 2,
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={Math.ceil(employers.count / pageSize)}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  color="primary"
                  sx={{ marginTop: 2 }}
                  onChange={(event, page) => {
                    getEmployers((page - 1) * pageSize);
                  }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Card>

      {/* Employer form dialog */}
      <Dialog
        open={openEmployerForm}
        onClose={() => {
          setOpenEmployerForm(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <EmployerForm
            employer={selectedEmployer}
            onSave={() => {
              getEmployers(0);
              setOpenEmployerForm(false);
            }}
            onCancel={() => {
              setOpenEmployerForm(false);
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Delete dialog */}
      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Employer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this employer ?{" "}
            {/* <span style={{ color: "#E90000" }}>
              {selectedEmployer.firstName}
            </span> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Restore dialog */}
      <Dialog
        open={openRestoreConfirmation}
        onClose={() => {
          setOpenRestoreConfirmation(false);
        }}
        fullWidth
      >
        <DialogTitle>Restore Employer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this employer ?{" "}
            {/* <span style={{ color: "#E90000" }}>
              {selectedEmployer.firstName}
            </span> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRestore} variant="contained">
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenRestoreConfirmation(false);
            }}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Employers;
