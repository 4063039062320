import { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

//project imports
import config from "../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const EmployerForm = (props) => {
  const { onSave, onCancel, employer } = props;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const genderOptions = [
    { value: "---", label: "---" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const stateOptions = [
    { value: "---", label: "---" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Goa", label: "Goa" },
    { value: "Karnataka", label: "Karnataka" },
  ];
  const maritialOptions = [
    { value: "---", label: "---" },
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" },
  ];

  const formik = useFormik({
    initialValues: employer,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      email: Yup.string("Enter your email")
        .max(50, "Must be 50 characters or less ")
        .email("Enter a valid email")
        .required("Required"),
      dateOfBirth: Yup.date("must be valid date")
        .min(new Date("01-01-1950"))
        .max(new Date())
        .required("Required"),
      phoneNumber: Yup.string()
        .required("Required")
        .max(10, "Max 10 digits are allowed")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        ),
      addressLine1: Yup.string()
        .max(255, "Must be 255 characters or less")
        .required("Required"),
      addressLine2: Yup.string().max(255, "Must be 255 characters or less"),
    }),

    onSubmit: (values) => {
      if (employer.id != 0) {
        axios
          .put(config.serverURL + "/api/Employers", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .post(config.serverURL + "/api/Authentication/EmployerSignup", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Grid container>
        {employer.id === 0 ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />

            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Employer
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Employer Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="h6">User Details</Typography>
            <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="firstName"
                  label="First Name *"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  fullWidth
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="lastName"
                  label="Last Name *"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  fullWidth
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="email"
                  label="Email Address*"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number *"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  fullWidth
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
            </Grid>
            <Typography variant="h6">Company Details</Typography>
            <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Company Name *"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  fullWidth
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="addressLine1"
                  label="Address Line 1 *"
                  onChange={formik.handleChange}
                  value={formik.values.addressLine1}
                  fullWidth
                  error={
                    formik.touched.addressLine1 &&
                    Boolean(formik.errors.addressLine1)
                  }
                  helperText={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="addressLine2"
                  label="Address Line 2"
                  onChange={formik.handleChange}
                  value={formik.values.addressLine2}
                  fullWidth
                  error={
                    formik.touched.addressLine2 &&
                    Boolean(formik.errors.addressLine2)
                  }
                  helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl sx={{ marginBottom: 3 }} fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="state"
                    label="State"
                    onChange={formik.handleChange}
                    value={formik.values.state}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  >
                    {stateOptions.map((options) => (
                      <MenuItem value={options.value}>{options.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="district"
                  label="District"
                  onChange={formik.handleChange}
                  value={formik.values.district}
                  fullWidth
                  error={formik.touched.city && Boolean(formik.errors.district)}
                  helperText={formik.touched.district && formik.errors.district}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="taluka"
                  label="Taluka"
                  onChange={formik.handleChange}
                  value={formik.values.taluka}
                  fullWidth
                  error={formik.touched.city && Boolean(formik.errors.taluka)}
                  helperText={formik.touched.taluka && formik.errors.taluka}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="city"
                  label="City/Town"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  fullWidth
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="pinCode"
                  label="Pin Code"
                  onChange={formik.handleChange}
                  value={formik.values.pinCode}
                  fullWidth
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="website"
                  label="Website"
                  onChange={formik.handleChange}
                  value={formik.values.website}
                  fullWidth
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                  sx={{ marginBottom: 3 }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployerForm;
