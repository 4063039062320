const adminMenuItems = [
  {
    path: "/admin/candidates",
    title: "Candidates",
  },
  {
    path: "/admin/employers",
    title: "Employers",
  },
  {
    path: "/admin/jobposts",
    title: "Job Posts",
  },
  // {
  //   path: "/admin/settings",
  //   title: "Settings",
  // },
  {
    path: "/admin/interviews",
    title: "Interviews",
  },
];

export default adminMenuItems;
