import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//material ui
import {
  Grid,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
  Pagination,
  TablePagination,
  Fab,
  Stack,
  TextField,
  InputAdornment,
  Alert,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from "@mui/material";

import PlaceIcon from "@mui/icons-material/Place";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PeopleIcon from "@mui/icons-material/People";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import CurrencyRupeeSharpIcon from "@mui/icons-material/CurrencyRupeeSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

//project components
import config from "../../../config";
import Job1 from "../../../assets/images/job1.png";

//third party
import axios from "axios";
import parse from "html-react-parser";
import JobPostForm from "../../admin/job-posts/JobPostForm";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Box } from "@mui/system";
import InterviewForm from "../../admin/job-posts/InterviewForm";
import { LoadingButton } from "@mui/lab";

const JobDetails = () => {
  const navigate = useNavigate();
  const { userId, userName, role, profileId, displayRole } = useSelector(
    (state) => state.userReducer
  );

  const [jobPost, setJobPost] = useState();
  const [openApplyConfirmation, setOpenApplyConfirmation] = useState(false);
  const [
    openRemoveApplicationConfirmation,
    setOpenRemoveApplicationConfirmation,
  ] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [jobApplication, setJobApplication] = useState();
  const [scheduledInterview, setScheduledInterview] = useState();

  const [successOpen, setSuccessOpen] = useState(false);
  const [successRemoveOpen, setSuccessRemoveOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getJobPost();
  }, [searchParams.get("ref")]);

  const getJobPost = () => {
    if (searchParams.get("ref") === null) {
      return;
    }
    let id = searchParams.get("ref");
    axios
      .get(config.serverURL + "/api/JobPosts/" + id)
      .then((response) => {
        const res = response.data.jobApplications.find(
          (element) => element.candidateId === profileId
        );
        if (res === undefined) {
          setAlreadyApplied(false);
        } else {
          setAlreadyApplied(true);
          setJobApplication(res);
          getInterViewDetails(res.id);
        }
        setJobPost(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInterViewDetails = (jobApplicationId) => {
    axios
      .get(
        config.serverURL +
          "/api/JobApplicationInterview/GetByJobApplicationIdAsync/" +
          jobApplicationId
      )
      .then((response) => {
        setScheduledInterview(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
  };
  const handleSuccessRemoveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessRemoveOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
        {jobPost != undefined ? (
          <Grid container spacing={3} sx={{ padding: 4 }}>
            <Grid item md={3} xs={12}>
              <Typography color="primary" variant="h6" sx={{ marginBottom: 2 }}>
                Job Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <PlaceIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.location}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <BusinessCenterIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.jobTypeDisplay}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <PeopleIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.noOfVaccancy} Vacanies
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <SchoolSharpIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  {jobPost.qualification}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <CurrencyRupeeSharpIcon fontSize="small" color="primary" />
                <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                  Salary : {jobPost.minSalary} - {jobPost.maxSalary} PM
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <WorkHistoryIcon fontSize="small" color="primary" />
                {jobPost.maxExprienceRequired > 0 ? (
                  <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                    Experience : {jobPost.minExprienceRequired} -{" "}
                    {jobPost.maxExprienceRequired} Years
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: "600", color: "#757575" }}>
                    Freshers are allowed
                  </Typography>
                )}
              </Stack>
              <Divider sx={{ mb: 2 }} />
              <Stack direction="row" justifyContent="space-between">
                <>
                  {alreadyApplied ? (
                    <LoadingButton
                      loading={submitting}
                      disabled={jobApplication.status > 0}
                      variant="outlined"
                      onClick={() => {
                        setOpenRemoveApplicationConfirmation(true);
                      }}
                    >
                      Remove Application
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      variant="contained"
                      loading={submitting}
                      onClick={() => {
                        axios
                          .get(
                            config.serverURL +
                              "/api/Candidates/GetProfilePercentage?candidateId=" +
                              profileId
                          )
                          .then((response) => {
                            if (response.status === 200) {
                              if (response.data > 0) {
                                if (response.data >= 80) {
                                  setOpenApplyConfirmation(true);
                                } else {
                                  alert(
                                    "Please complete your profile before applying to job openings"
                                  );
                                }
                              } else {
                                alert(
                                  "Something went wrong, please try again."
                                );
                              }
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            setErrorOpen(true);
                          });
                      }}
                    >
                      Apply
                    </LoadingButton>
                  )}
                </>
                <Tooltip title="Go Back">
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      let prePage = searchParams.get("prePage");
                      navigate(prePage);
                    }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </Stack>
              <Divider sx={{ marginY: 2 }} />
              <Box sx={{ marginTop: 2 }}>
                {(() => {
                  switch (jobApplication?.status) {
                    case 0:
                      return (
                        <Typography
                          textAlign="left"
                          variant="h6"
                          color="secondary"
                        >
                          You have applied for this job post.
                        </Typography>
                      );
                    case 1:
                      return (
                        <>
                          <Typography
                            textAlign="left"
                            variant="h6"
                            color="secondary"
                          >
                            You have been selected for interview for this job
                            post.
                          </Typography>
                          {scheduledInterview != undefined ? (
                            <>
                              {scheduledInterview.map((interview, index) => {
                                return (
                                  <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                      padding: 2,
                                      marginTop: 3,
                                      backgroundColor: "#F0F0F0",
                                    }}
                                  >
                                    <Typography variant="h6">
                                      Interview Details
                                    </Typography>
                                    <Divider sx={{ marginY: 1 }} />
                                    <Typography
                                      color="primary"
                                      sx={{ fontWeight: 500, marginBottom: 1 }}
                                    >
                                      {interview.interview.description}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 500 }}>
                                      Interview Date:
                                    </Typography>
                                    <Typography>
                                      {
                                        new Date(
                                          interview.interview.dateTime.toString() +
                                            "Z"
                                        )
                                          .toISOString()
                                          .split("T")[0]
                                      }
                                    </Typography>
                                    <Typography
                                      sx={{ fontWeight: 500, marginTop: 1 }}
                                    >
                                      Venue
                                    </Typography>
                                    <Typography>
                                      {interview.interview.venue}
                                    </Typography>
                                  </Card>
                                );
                              })}
                            </>
                          ) : null}
                        </>
                      );
                    case 2:
                      return (
                        <Typography
                          textAlign="left"
                          variant="h6"
                          color="secondary"
                        >
                          You have beed placed for this job post.
                        </Typography>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: "550",
                  fontSize: "1.4em",
                  color: "#001F6A",
                }}
              >
                {jobPost.title}
              </Typography>
              <Typography sx={{ color: "#757575" }}>
                {parse(jobPost.jobDescription)}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Card>

      <Dialog
        open={openApplyConfirmation}
        keepMounted
        onClose={() => {
          setOpenApplyConfirmation(false);
        }}
      >
        <DialogTitle>{"Appy for job post"}</DialogTitle>
        <DialogContent>
          <Typography>
            {role == 1
              ? "Are you sure you want to apply for this job post?"
              : "You need to sign in before applying for job"}
          </Typography>
        </DialogContent>
        <DialogActions>
          {role == 1 ? (
            <Button
              variant="contained"
              onClick={() => {
                setSubmitting(true);
                let jobApplication = {
                  id: 0,
                  jobPostId: searchParams.get("ref"),
                  candidateId: profileId,
                  message: "",
                };
                axios
                  .post(
                    config.serverURL + "/api/JobApplications",
                    jobApplication
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      setSubmitting(false);
                      getJobPost();
                      setSuccessOpen(true);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    setErrorOpen(true);
                  });
                setOpenApplyConfirmation(false);
              }}
            >
              Apply
            </Button>
          ) : null}
          <Button
            variant="outlined"
            onClick={() => {
              setOpenApplyConfirmation(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveApplicationConfirmation}
        keepMounted
        onClose={() => {
          setOpenRemoveApplicationConfirmation(false);
        }}
      >
        <DialogTitle>{"Remove application"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove your application from this job post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setSubmitting(true);
              axios
                .delete(
                  config.serverURL + "/api/JobApplications/" + jobApplication.id
                )
                .then((response) => {
                  if (response.status === 200) {
                    getJobPost();
                    setSuccessRemoveOpen(true);
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorOpen(true);
                  setSubmitting(false);
                });
              setOpenRemoveApplicationConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRemoveApplicationConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#009933" }}
          variant="filled"
        >
          Job application submitted sccuessfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={successRemoveOpen}
        autoHideDuration={6000}
        onClose={handleSuccessRemoveClose}
      >
        <Alert
          onClose={handleSuccessRemoveClose}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#009933" }}
          variant="filled"
        >
          Job application removed sccuessfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Error in submitting job application!
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default JobDetails;
