import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import SchoolIcon from "@mui/icons-material/School";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupIcon from "@mui/icons-material/Group";

//project imports
import Job1 from "../../assets/images/job1.png";

const JobPost = (props) => {
  const { jobPost, type } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  return (
    <Card
      variant="outlined"
      key={jobPost.number}
      onClick={() => {
        if (type === 2) {
          navigate(
            "/admin/jobpost?ref=" + jobPost.id + "&prePage=/admin/jobposts"
          );
        } else if (type === 1) {
          navigate("/jobdetails?ref=" + jobPost.id + "&prePage=/profile");
        } else {
          navigate("/jobdetails?ref=" + jobPost.id + "&prePage=/jobs");
        }
      }}
      sx={{
        minHeight: "150px",
        padding: 2,
        display: "flex",
        direction: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="left">
            {jobPost.title}
          </Typography>
          {role > 2 && (
            <Typography variant="subtitle2">
              Applications Received: {jobPost.applicationCount}
            </Typography>
          )}
        </Grid>
        {role > 2 && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              textAlign="left"
              color="primary.main"
            >
              {jobPost.employerName}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          lg={3}
          xl={3}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocationOnRoundedIcon
            fontSize="small"
            sx={{
              marginRight: 1,
              color: theme.palette.secondary.main,
            }}
          />
          <Typography variant="body2">{jobPost.location}</Typography>
        </Grid>

        <Grid
          item
          lg={3}
          xl={3}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <BusinessCenterIcon
            color="primary"
            fontSize="small"
            sx={{
              marginRight: 1,
              color: theme.palette.secondary.main,
            }}
          />
          {jobPost.maxExprienceRequired > 0 ? (
            <Typography variant="body2">
              {jobPost.minExprienceRequired}-{jobPost.maxExprienceRequired}{" "}
              Years
            </Typography>
          ) : (
            <Typography variant="body2">Freshers</Typography>
          )}
        </Grid>

        <Grid
          item
          lg={3}
          xl={3}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <SchoolIcon
            fontSize="small"
            color="secondary.main"
            sx={{
              marginRight: 1,
              color: theme.palette.secondary.main,
            }}
          />
          <Typography variant="body2">{jobPost.qualification}</Typography>
        </Grid>

        <Grid
          item
          lg={3}
          xl={3}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <GroupIcon
            color="primary"
            fontSize="small"
            sx={{
              marginRight: 1,
              color: theme.palette.secondary.main,
            }}
          />
          <Typography variant="body2">{jobPost.noOfVaccancy}</Typography>
        </Grid>

        <Grid item lg={3} xl={3} md={6} sm={6} xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
          >
            Salary
          </Typography>
          <Typography variant="body2">
            {jobPost.minSalary}-{jobPost.maxSalary} PM
          </Typography>
        </Grid>
        <Grid item lg={3} xl={3} md={6} sm={6} xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
          >
            Job Type:
          </Typography>
          <Typography variant="body2">{jobPost.jobTypeDisplay}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default JobPost;
