import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PageHeader from "../../../assets/images/page-header.png";
import AboutImage from "../../../assets/images/about.jpg";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const About = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${PageHeader})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          align: "center",
          height: "300px",
          width: "100%",
          padding: "100px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ color: "#ffffff" }}>
            About
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginY: 5 }}
      >
        <Grid container spacing={5} maxWidth="xl" sx={{ padding: 2 }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Stack spacing={4}>
              <Typography variant="h4" color="primary">
                About Us
              </Typography>
              <Typography>
                Optima jobs is fastest growing HR firm that provides total HR
                solutions, which includes Executive Search, HR Services, Legal
                Compliance, Employee Assessment & Trainings. Our mission is to
                provide total HR Solutions with perfection, dedication &
                professionalism to the clients willing to grow.
              </Typography>
              <Typography>
                Optima Jobs is established in 5th August 2017, we have expertise
                in placing candidates in every sector. With the team of young &
                qualified professionals we cater the various requirements of the
                companies effectively & successfully. We are connected with our
                clients on various platform and we are inclined to our core
                values to make our bond stronger.
              </Typography>
              <Typography variant="h4" color="primary">
                Our Core Values
              </Typography>
              <Stack>
                <Stack direction="row" spacing={1}>
                  <AlignHorizontalCenterIcon
                    fontSize="large"
                    sx={{ color: "#0d42ff" }}
                  />
                  <Stack>
                    <Typography align="justify" variant="h6">
                      Perfection
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack direction="row" spacing={1}>
                  <AlignHorizontalCenterIcon
                    fontSize="large"
                    sx={{ color: "#0d42ff" }}
                  />
                  <Stack>
                    <Typography align="justify" variant="h6">
                      Dedication
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack direction="row" spacing={1}>
                  <AlignHorizontalCenterIcon
                    fontSize="large"
                    sx={{ color: "#0d42ff" }}
                  />
                  <Stack>
                    <Typography align="justify" variant="h6">
                      Professionalism
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src={AboutImage} alt="about_us_image" width="100%" />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={5}
        sx={{
          marginY: 5,
          paddingX: 5,
          paddingBottom: 5,
          backgroundColor: "#e6f5ff",
        }}
      >
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h4" color="primary">
            Our Vision
          </Typography>
          <Typography textAlign="center" variant="h6">
            Our Vision is to become India’s leading HR firm by providing utmost
            services to our clients.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h4" color="primary">
            Our MIssion
          </Typography>
          <Typography textAlign="center" variant="h6">
            Our mission is to provide total HR Solutions with perfection,
            dedication & professionalism to the clients willing to grow.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginY: 10, padding: 2 }}
      >
        <Grid
          container
          maxWidth="xl"
          spacing={5}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Typography variant="h3" color="primary">
                250+
              </Typography>
              <HorizontalRuleIcon fontSize="large" sx={{ color: "#0d42ff" }} />
              <Typography variant="h6">Clients Served</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Typography variant="h3" color="primary">
                3500+
              </Typography>
              <HorizontalRuleIcon fontSize="large" sx={{ color: "#0d42ff" }} />
              <Typography variant="h6">Candidates Registered</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Typography variant="h3" color="primary">
                1000+
              </Typography>
              <HorizontalRuleIcon fontSize="large" sx={{ color: "#0d42ff" }} />
              <Typography variant="h6">Candidates Placed</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginY: 10, padding: 2 }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={3}
          maxWidth="md"
        >
          <Grid item xs={12}>
            <Typography variant="h4" color="primary" textAlign="center">
              FREQUENTLY ASKED QUESTIONS
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HorizontalRuleIcon fontSize="large" sx={{ color: "#0d42ff" }} />
          </Grid>
          <Grid item xs={12}>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  How much do you charge to the candidates?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  No we do not charge to candidates. Its free for candidates.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  How much do you charge to the companies?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  If any company wants to enquire for this please follow the
                  below link.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                background: "#fafafa",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  Do you provide Government Jobs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  No we do not provide Government jobs. We only provide Jobs in
                  Private companies.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  How is your recruitment process?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  You just need to fill our form and upload your resume. You
                  will be contacted from our office for the interview calls.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Do you provide trainings?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  Yes we do provide Trainings and Trainers for candidates as
                  well as to companies.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  In which locations you provide jobs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  We provide jobs in all over India and preferably in West
                  Maharashtra region ( Kolhapur , Sangli, Karad, Satara, Goa,
                  Belgaum)
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  Do you provide support for Resume writing?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  Yes we do assist you in Resume writing.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  Do you provide jobs for freshers?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  Yes we provide Jobs for Freshers as well as Experienced
                  candidates.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  Do you provide Work from home jobs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  Yes we provide opportunities for all type of Jobs
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ background: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  Do you provide part time jobs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="justify">
                  Yes as per requirements from companies we provide these kind
                  of Jobs.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
