import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobPostFilterData: {
    companyName: "",
    jobType: 0,
    position: "",
    location: "",
    skill: "",
    minSalary: 0,
    maxSalary: 0,
    minExperience: 0,
    maxExperience: 0,
    status: 0,
    skip: 0,
    take: 50,
  },
  currentPage: 0,
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setJobPostFilterData: (state, action) => {
      state.customerFilterData = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJobPostFilterData, setCurrentPage } = generalSlice.actions;

export default generalSlice.reducer;
