const websiteMenuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/jobs",
    title: "Jobs",
  },
  {
    path: "/services",
    title: "Services",
  },
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/contact",
    title: "Contact",
  },
];

export default websiteMenuItems;
