
import { Card, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import config from "../../../../config";
import axios from "axios";
import PlaceIcon from "@mui/icons-material/Place";
import JobPost from "../../../../components/job-post/JobPost";

const EmployerDetails = () => {
  const theme=useTheme();
  const [employerDetails, setEmployerDetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const getEmployerDetails = () => {
    if (searchParams.get("ref") === null) {
      return;
    }
    let id = searchParams.get("ref");
    axios
      .get(config.serverURL + "/api/Employers/" + id)
      .then((response) => {
        setEmployerDetails(response.data);
        console.log(response.data)
        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getEmployerDetails();
  },[]);
  
  return <div>
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
      <Grid
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="white.main">
              Employer Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ padding: 2 }}>
              
                <Grid  item sm={12} xs={12}>
                  <Card
                    variant="outlined"
                    
                    sx={{
                      minHeight: "150px",
                      padding: 2,
                      display: "flex",
                      direction: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: theme.palette.primary.light,
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow:
                          "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography color={theme.palette.primary.dark} variant="h5" textAlign="left">
                          {employerDetails.name}
                        </Typography>
                        <Typography  variant="body1">
                          {employerDetails.city} 
                        </Typography>
                      </Grid>
                      
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Name:
                        </Typography>
                        <Typography variant="body2">
                          {employerDetails.firstName} {employerDetails.middleName} {employerDetails.lastName}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                        <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Address:
                        </Typography>
                        <Typography align="left" variant="subtitle1">{employerDetails.addressLine1}, {employerDetails.addressLine2}, {employerDetails.city} {employerDetails.pinCode}</Typography>
                      </Grid>
                      <Grid item  lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}
                        >
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Email:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.email}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Mobile No.:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.phoneNumber}
                        </Typography>
                      </Grid>
                      
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Taluka:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.taluka}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          District:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.district}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          State:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.state}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}
                        xl={3}
                        md={6}
                        sm={6}
                        xs={12}>
                      <Typography color={theme.palette.grey[600]} variant="subtitle2">
                          Website:
                        </Typography>
                        <Typography variant="subtitle1">
                          {employerDetails.website}
                        </Typography>
                      </Grid>
                      
                     
                    </Grid>
                  </Card>
                  {/* <Grid item sm={12} xs={12}>
                  <Box key={row.id}  sx={{ marginBottom: 2 }}>
                  <JobPost jobPost={row} type={2} />
                </Box>
                  </Grid> */}
                </Grid>
             
            </Grid>
      </Card>
    </Grid>
  </div>;
};

export default EmployerDetails;
