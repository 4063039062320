import { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";

const CandidateFilterDlg = (props) => {
  const { onSave, onCancel, filterData } = props;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: filterData,
    validationSchema: Yup.object({
      name: Yup.string().max(50, "Must be 50 characters or less"),
      phoneNumber: Yup.string().max(10, "Must be 10 digits or less"),
      location: Yup.string().max(50, "Must be 50 characters or less"),
      degreeName: Yup.string().max(50, "Must be 50 characters or less"),
      skill: Yup.string().max(50, "Must be 50 characters or less"),
      companyName: Yup.string().max(50, "Must be 50 characters or less"),
      position: Yup.string().max(50, "Must be 50 characters or less"),
      minExperienceYears: Yup.number().min(0, "Should be >= 0"),
      maxExperienceYears: Yup.number().min(0, "Should be >= 0"),
      minSalary: Yup.number().min(0, "Should be >= 0"),
      maxSalary: Yup.number().min(0, "Should be >= 0"),
    }),

    onSubmit: (values) => {
      if (values.exprienceMinYears == "") {
        values.exprienceMinYears = 0;
      }
      if (values.exprienceMinMonths == "") {
        values.exprienceMinMonths = 0;
      }
      if (values.exprienceMaxYears == "") {
        values.exprienceMaxYears = 0;
      }
      if (values.exprienceMaxMonths == "") {
        values.exprienceMaxMonths = 0;
      }
      if (values.minSalary == "") {
        values.minSalary = 0;
      }
      if (values.maxSalary == "") {
        values.maxSalary = 0;
      }
      values.filterApplied = true;
      onSave(values);
    },
  });
  return (
    <>
      <Grid container>
        <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
          <FilterListIcon />

          <Typography
            color={theme.palette.primary.dark}
            variant="h6"
            textAlign="center"
            sx={{ marginLeft: 2 }}
          >
            Candidate Filters
          </Typography>
        </Stack>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="name"
              label="Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="phoneNumber"
              label="Phone Number"
              onChange={formik.handleChange}
              value={formik.values.naphoneNumberme}
              fullWidth
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="location"
              label="Location"
              onChange={formik.handleChange}
              value={formik.values.location}
              fullWidth
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                label="Fender"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
              >
                <MenuItem key={0} value={null}>
                  NA
                </MenuItem>
                <MenuItem key={1} value="Male">
                  Male
                </MenuItem>
                <MenuItem key={2} value="Female">
                  Female
                </MenuItem>
                <MenuItem key={3} value="Other">
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="degreeName"
              label="Qualification"
              onChange={formik.handleChange}
              value={formik.values.degreeName}
              fullWidth
              error={
                formik.touched.degreeName && Boolean(formik.errors.degreeName)
              }
              helperText={formik.touched.degreeName && formik.errors.degreeName}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="skill"
              label="Skills"
              onChange={formik.handleChange}
              value={formik.values.skill}
              fullWidth
              error={formik.touched.skill && Boolean(formik.errors.skill)}
              helperText={formik.touched.skill && formik.errors.skill}
            />
          </Grid>
          {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="companyName"
              label="Current Work Place"
              onChange={formik.handleChange}
              value={formik.values.companyName}
              fullWidth
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid> */}
          {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="position"
              label="Position"
              onChange={formik.handleChange}
              value={formik.values.position}
              fullWidth
              error={formik.touched.position && Boolean(formik.errors.position)}
              helperText={formik.touched.position && formik.errors.position}
            />
          </Grid> */}
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="minExperienceYears"
              type="number"
              label="Min Experience (Years)"
              onChange={formik.handleChange}
              value={formik.values.minExperienceYears}
              fullWidth
              error={
                formik.touched.minExperienceYears &&
                Boolean(formik.errors.minExperienceYears)
              }
              helperText={
                formik.touched.minExperienceYears &&
                formik.errors.minExperienceYears
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="maxExperienceYears"
              type="number"
              label="Max Experience (Years)"
              onChange={formik.handleChange}
              value={formik.values.maxExperienceYears}
              fullWidth
              error={
                formik.touched.maxExperienceYears &&
                Boolean(formik.errors.maxExperienceYears)
              }
              helperText={
                formik.touched.maxExperienceYears &&
                formik.errors.maxExperienceYears
              }
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="minSalary"
              label="Min Monthly Salary"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.minSalary}
              fullWidth
              error={
                formik.touched.minSalary && Boolean(formik.errors.minSalary)
              }
              helperText={formik.touched.minSalary && formik.errors.minSalary}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextField
              name="maxSalary"
              label="Max Monthly Salary"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.maxSalary}
              fullWidth
              error={
                formik.touched.maxSalary && Boolean(formik.errors.maxSalary)
              }
              helperText={formik.touched.maxSalary && formik.errors.maxSalary}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isDeleted"
                    checked={formik.values.isDeleted}
                    onChange={formik.handleChange}
                  />
                }
                label="Show only deleted"
              />
            </FormGroup>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid>
              <Button variant="contained" type="submit">
                Apply
              </Button>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CandidateFilterDlg;
