import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

//import material ui
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
  Pagination,
  TablePagination,
  Fab,
  Stack,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

//project components
import config from "../../../config";
import useRefreshToken from "../../../hooks/useRefreshToken";

//third party
import axios from "../../../api";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

import CandidateFilterDlg from "./CandidateFilterDlg";
import { Box } from "@mui/system";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const Candidates = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const axiosAuth = useAxiosAuth();
  const pageSize = 100;

  const [checked, setChecked] = useState(false);
  const [candidates, setCandidates] = useState();
  const [deletedCandidates, setDeletedCandidates] = useState();
  const [filterData, setFilterData] = useState({
    name: "",
    phoneNumber: "",
    location: "",
    gender: "",
    degreeName: "",
    minExperienceYears: 0,
    maxExperienceYears: 0,
    skill: "",
    companyName: "",
    position: "",
    minSalary: 0,
    maxSalary: 0,
    isDeleted: false,
    skip: 0,
    take: 10,
    filterApplied: false,
  });
  const [openFilterDlg, setOpenFilterDlg] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getCandidates = (skip) => {
    filterData.skip = skip;
    filterData.take = pageSize;
    axiosAuth
      .post("/api/Candidates/GetFilteredCandidates", filterData)
      .then((response) => {
        setCandidates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDeletedCandidates = () => {
    axiosAuth
      .get("/api/Candidates/GetDeletedCandidate")
      .then((response) => {
        setDeletedCandidates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCandidates();
  }, [filterData]);

  useEffect(() => {
    if (checked) {
      getDeletedCandidates();
    }
  }, [checked]);

  return (
    <Grid
      container
      maxWidth="xl"
      sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}
    >
      <Card elevation={3} sx={{ width: "100%" }}>
        <Grid
          container
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="white.main">
              Candidates
            </Typography>
            <Grid>
              <Tooltip title="Apply filters">
                <IconButton
                  color="white"
                  onClick={() => {
                    setOpenFilterDlg(true);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Add New">
                <IconButton
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    refresh();
                  }}
                >
                  <AddIcon sx={{ color: "white" }} />
                </IconButton>
              </Tooltip> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {filterData.filterApplied === true ? (
              <Card
                variant="outlined"
                sx={{
                  margin: 2,
                  padding: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography
                    color="primary"
                    variant="h6"
                    sx={{ fontWeight: "600" }}
                  >
                    Filters Applied:
                  </Typography>
                  {filterData.name != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Name: <b>{filterData.name}</b>
                    </Typography>
                  ) : null}
                  {filterData.location != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Location: <b>{filterData.location}</b>
                    </Typography>
                  ) : null}
                  {filterData.phoneNumber != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Phone No.: <b>{filterData.phoneNumber}</b>
                    </Typography>
                  ) : null}
                  {filterData.gender != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Gender: <b>{filterData.gender}</b>
                    </Typography>
                  ) : null}
                  {filterData.degreeName != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Degree: <b>{filterData.degreeName}</b>
                    </Typography>
                  ) : null}
                  {filterData.maxExperienceYears > 0 ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      <b>
                        {filterData.minExperienceYears} {" - "}
                        {filterData.maxExperienceYears} Years
                      </b>
                    </Typography>
                  ) : null}
                  {filterData.skill != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Skills: <b>{filterData.skill}</b>
                    </Typography>
                  ) : null}
                  {filterData.companyName != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Company: <b>{filterData.companyName}</b>
                    </Typography>
                  ) : null}
                  {filterData.position != "" ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Position: <b>{filterData.position}</b>
                    </Typography>
                  ) : null}
                  {filterData.minSalary != 0 ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Min. Monthly Salary: <b>{filterData.minSalary}</b>
                    </Typography>
                  ) : null}
                  {filterData.maxSalary != 0 ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Max. Monthly Salary: <b>{filterData.maxSalary}</b>
                    </Typography>
                  ) : null}
                  {filterData.isDeleted === true ? (
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      Only deleted
                    </Typography>
                  ) : null}
                </Stack>
                <Box>
                  <Tooltip title="Clear Filter">
                    <Fab
                      sx={{ marginLeft: 1, marginY: "auto" }}
                      color="primary"
                      size="small"
                      onClick={() => {
                        setFilterData({
                          name: "",
                          phoneNumber: "",
                          location: "",
                          gender: "",
                          degreeName: "",
                          exprienceMinMonths: 0,
                          exprienceMinYears: 0,
                          exprienceMaxMonths: 0,
                          exprienceMaxYears: 0,
                          skill: "",
                          companyName: "",
                          position: "",
                          minSalary: 0,
                          maxSalary: 0,
                          isDeleted: false,
                          skip: 0,
                          take: 10,
                          filterApplied: false,
                        });
                      }}
                    >
                      <FilterListOffIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              </Card>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {candidates != undefined ? (
              <Box sx={{ padding: 1, marginTop: 1 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  justifyContent="end"
                >
                  <Typography>Total: {candidates.count}</Typography>
                  <Pagination
                    count={Math.ceil(candidates.count / pageSize)}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    color="primary"
                    sx={{ marginTop: 2 }}
                    onChange={(event, page) => {
                      getCandidates((page - 1) * pageSize);
                    }}
                  />
                </Stack>
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            {candidates != undefined && checked != true ? (
              <Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                  }}
                >
                  {candidates.candidates?.map((candidate, index) => {
                    return (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{
                          margin: 1,
                          padding: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography>
                            <b>Name - (Mobile):</b> {candidate.name} - (
                            {candidate.primaryMobileNumber})
                          </Typography>
                          <Typography>
                            <b>Highest Qualification:</b>{" "}
                            {candidate.highestQualification}
                          </Typography>
                          <Typography>
                            <b>Skills:</b> {candidate.skills}
                          </Typography>
                          <Typography>
                            <b>Gender:</b> {candidate.gender}
                          </Typography>
                          <Typography>
                            <b>Marrital Status:</b> {candidate.marritalStatus}
                          </Typography>
                          <Typography>
                            <b>Location:</b> {candidate.city}
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography>
                              <b>Profile:</b>
                            </Typography>
                            <CircularProgressWithLabel
                              value={candidate.profilePercentage}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Tooltip title="View Details">
                            <IconButton
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                navigate("/profile?ref=" + candidate.id);
                              }}
                            >
                              <ArticleIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Typography>{candidate.number}</Typography>
                        </Stack>
                      </Card>
                    );
                  })}
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <TableContainer sx={{ padding: 2 }}>
                    <Table
                      sx={{
                        borderWidth: 1,
                        border: "solid",
                        borderColor: "#f2f2f2",
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#e6eeff" }}>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell align="left">Name - (Mobile)</TableCell>
                          <TableCell align="left">
                            Highest Qualification
                          </TableCell>
                          <TableCell align="left">Skills</TableCell>
                          <TableCell align="left">Gender</TableCell>
                          <TableCell align="left">Marrital Status</TableCell>
                          <TableCell align="left">Location</TableCell>
                          <TableCell align="left">Profile %</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {candidates.candidates?.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.number}
                            </TableCell>
                            <TableCell align="left">
                              {row.name} - ({row.primaryMobileNumber})
                            </TableCell>
                            <TableCell align="left">
                              {row.highestQualification}
                            </TableCell>
                            <TableCell align="left">{row.skills}</TableCell>
                            <TableCell align="left">{row.gender}</TableCell>
                            <TableCell align="left">
                              {row.marritalStatus}
                            </TableCell>
                            <TableCell align="left">{row.city}</TableCell>
                            <TableCell align="left">
                              <CircularProgressWithLabel
                                value={row.profilePercentage}
                              />
                            </TableCell>

                            <TableCell align="right">
                              <Tooltip title="View Details">
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    navigate("/profile?ref=" + row.id);
                                  }}
                                >
                                  <ArticleIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              {/*<IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ) : candidates == undefined && checked != true ? (
              <Box
                sx={{ display: "flex", justifyContent: "center", marginY: 5 }}
              >
                <Stack
                  direction="column"
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5" textAlign="center">
                    Loading Candidates...
                  </Typography>
                  <CircularProgress />
                </Stack>
              </Box>
            ) : checked == true ? (
              //------------------------SHOW DELETED CANDIDATES-----------------------//
              <Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                  }}
                >
                  {deletedCandidates?.map((candidate, index) => {
                    return (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{
                          margin: 1,
                          padding: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography>
                            <b>Name:</b> {candidate.name}
                          </Typography>
                          <Typography>
                            <b>Email:</b> {candidate.email}
                          </Typography>
                          <Typography>
                            <b>Phone No.:</b> {candidate.phoneNumber}
                          </Typography>
                          <Typography>
                            <b>City:</b> {candidate.city}
                          </Typography>
                        </Stack>
                        <Stack
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Tooltip title="View Details">
                            <IconButton
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                navigate("/profile?ref=" + candidate.id);
                              }}
                            >
                              <ArticleIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Typography>{index + 1}</Typography>
                        </Stack>
                      </Card>
                    );
                  })}
                </Box>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <TableContainer sx={{ padding: 2 }}>
                    <Table
                      sx={{
                        borderWidth: 1,
                        border: "solid",
                        borderColor: "#f2f2f2",
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#e6eeff" }}>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Phone No.</TableCell>
                          <TableCell align="left">City</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deletedCandidates?.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              {row.primaryMobileNumber},{" "}
                              {row.alternateMobileNumber}
                            </TableCell>
                            <TableCell align="left">{row.city}</TableCell>
                            <TableCell align="right">
                              <Tooltip title="View Details">
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    navigate("/profile?ref=" + row.id);
                                  }}
                                >
                                  <ArticleIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              {/*<IconButton color="error" size="small" onClick={() => {}}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {candidates != undefined ? (
              <Box
                sx={{
                  paddingX: 2,
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={Math.ceil(candidates.count / pageSize)}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  color="primary"
                  onChange={(event, page) => {
                    getCandidates((page - 1) * pageSize);
                  }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Card>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openFilterDlg}
        onClose={() => {
          setOpenFilterDlg(false);
        }}
      >
        <DialogContent>
          <CandidateFilterDlg
            filterData={filterData}
            onCancel={() => {
              setOpenFilterDlg(false);
            }}
            onSave={(values) => {
              setFilterData(values);
              setOpenFilterDlg(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Candidates;
