import React from "react";

import Banner from "./banner";
import { Grid } from "@mui/material";
import JobList from "./job-list";
import TagLine from "./tagline";
//import Testimonials from "../landing-page/Testimonials/index";
//import Advertisement from "../landing-page/Advertisement/index";
//import AddStrip from "../landing-page/Advertisement/AddStrip";

const LandingPage = () => {
  return (
    <Grid>
      <Banner />
      {/* <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 5, paddingX: 2 }}>
        <Advertisement />
      </Grid> */}
      {/* <Grid>
        <AddStrip />
      </Grid> */}
      <Grid>
        <TagLine />
      </Grid>
      <Grid maxWidth="xl" sx={{ marginX: "auto" }}>
        <JobList />
      </Grid>
      {/* <Grid maxWidth="xl" sx={{ marginX: "auto" }}>
        <Testimonials />
      </Grid> */}
    </Grid>
  );
};

export default LandingPage;
