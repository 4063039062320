import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Box, Container } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import axios from "axios";
import config from "../../../../config";
import JobPost from "../../../../components/job-post/JobPost";
import { motion } from "framer-motion";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JobList = () => {
  const [value, setValue] = useState(0);
  const [jobPosts, setJobPosts] = useState();
  const retrieveJobData = () => {
    let filterData = {
      companyName: "",
      jobType: 0,
      position: "",
      location: "",
      skill: "",
      minSalary: 0,
      maxSalary: 0,
      minExperience: 0,
      maxExperience: 0,
      status: 0,
      skip: 0,
      take: 10,
    };
    axios
      .post(config.serverURL + "/api/JobPosts/Search", filterData)
      .then((response) => {
        if (response.status === 200) {
          setJobPosts(response.data.jobPosts);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    retrieveJobData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <motion.div
        className="box"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <Grid container spacing={2} sx={{ paddingX: 2, marginY: 3 }}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              Recent Jobs
            </Typography>
          </Grid>
          {jobPosts?.map((row) => (
            <Grid item xs={12} md={6} key={row.id}>
              <JobPost jobPost={row} />
            </Grid>
          ))}
        </Grid>
        {/* <Tabs value={value} onChange={handleChange} centered>
            <Tab
              sx={{ fontSize: "18px", fontFamily: "'Poppins', sans-serif" }}
              label="Recent Jobs"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ fontSize: "18px", fontFamily: "'Poppins', sans-serif" }}
              label="Featured Jobs"
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container spacing={1}>
              {jobPosts?.map((row) => (
                <Grid item xs={6} key={row.id}>
                  <JobPost jobPost={row} />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {jobPosts?.map((row) => (
              <Box key={row.id}>
                <JobPost jobPost={row} />
              </Box>
            ))}
          </TabPanel> */}
      </motion.div>
    </>
  );
};

export default JobList;
