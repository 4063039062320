import { useState, useEffect } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//project imports
import config from "../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const JobPostForm = (props) => {
  const { onSave, onCancel, jobPost } = props;
  const theme = useTheme();

  const [employers, setEmployers] = useState();
  const [loading, setLoading] = useState(false);

  const getEmployers = (skip) => {
    axios
      .get(
        config.serverURL +
          "/api/Employers/GetPeginated?skip=" +
          skip +
          "&take=100"
      )
      .then((response) => {
        setEmployers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmployers(0);
  }, []);

  const formik = useFormik({
    initialValues: jobPost,
    validationSchema: Yup.object({
      title: Yup.string()
        .max(80, "Must be 80 characters or less")
        .required("Required"),
      jobDescription: Yup.string().max(2000, "Must be 2000 characters or less"),
      qualification: Yup.string()
        .max(255, "Must be 255 characters or less")
        .required("Required"),
      location: Yup.string()
        .max(255, "Must be 255 characters or less")
        .required("Required"),
      minSalary: Yup.number().min(0, "Must be greater than 0"),
      maxSalary: Yup.number().min(0, "Must be greater than 0"),
      noOfVaccancy: Yup.number().min(0, "Must be greater than 0"),
      minExprienceRequired: Yup.number().min(0, "Must be greater than 0"),
      maxExprienceRequired: Yup.number().min(0, "Must be greater than 0"),
    }),

    onSubmit: (values) => {
      if (jobPost.id != 0) {
        axios
          .put(config.serverURL + "/api/JobPosts", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .post(config.serverURL + "/api/JobPosts", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Grid container>
        {jobPost.id === 0 ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <WorkOutlineIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Job Post
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <WorkOutlineIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Job Post Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack>
              <FormControl sx={{ marginBottom: 3 }} fullWidth>
                <InputLabel>Employer</InputLabel>
                <Select
                  name="employerId"
                  label="Employer"
                  onChange={formik.handleChange}
                  value={formik.values.employerId}
                  error={
                    formik.touched.employerId &&
                    Boolean(formik.errors.employerId)
                  }
                  helperText={
                    formik.touched.employerId && formik.errors.employerId
                  }
                >
                  {employers?.employers?.map((employer) => (
                    <MenuItem value={employer.id} key={employer.id}>
                      {employer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginBottom: 3 }} fullWidth>
                <InputLabel>Job Type</InputLabel>
                <Select
                  name="jobTypeId"
                  label="Job Type"
                  onChange={formik.handleChange}
                  value={formik.values.jobTypeId}
                  error={
                    formik.touched.jobTypeId && Boolean(formik.errors.jobTypeId)
                  }
                  helperText={
                    formik.touched.jobTypeId && formik.errors.jobTypeId
                  }
                >
                  <MenuItem value={1}>Part Time</MenuItem>
                  <MenuItem value={2}>Full Time</MenuItem>
                  <MenuItem value={3}>Contract Basis</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="location"
                label="Location *"
                onChange={formik.handleChange}
                value={formik.values.location}
                fullWidth
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                name="qualification"
                label="Qualifications Required"
                onChange={formik.handleChange}
                value={formik.values.qualification}
                fullWidth
                error={
                  formik.touched.qualification &&
                  Boolean(formik.errors.qualification)
                }
                helperText={
                  formik.touched.qualification && formik.errors.qualification
                }
                sx={{ marginBottom: 3 }}
              />
              <Typography sx={{ marginBottom: 3 }}>Salary / Month</Typography>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="minSalary"
                  type="number"
                  label="Min. *"
                  onChange={formik.handleChange}
                  value={formik.values.minSalary}
                  fullWidth
                  error={
                    formik.touched.minSalary && Boolean(formik.errors.minSalary)
                  }
                  helperText={
                    formik.touched.minSalary && formik.errors.minSalary
                  }
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  name="maxSalary"
                  type="number"
                  label="Max.*"
                  onChange={formik.handleChange}
                  value={formik.values.maxSalary}
                  fullWidth
                  error={
                    formik.touched.maxSalary && Boolean(formik.errors.maxSalary)
                  }
                  helperText={
                    formik.touched.maxSalary && formik.errors.maxSalary
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Stack>

              <Typography sx={{ marginBottom: 3 }}>
                Experience Required
              </Typography>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="minExprienceRequired"
                  type="number"
                  label="Min."
                  onChange={formik.handleChange}
                  value={formik.values.minExprienceRequired}
                  fullWidth
                  error={
                    formik.touched.minExprienceRequired &&
                    Boolean(formik.errors.minExprienceRequired)
                  }
                  helperText={
                    formik.touched.minExprienceRequired &&
                    formik.errors.minExprienceRequired
                  }
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  name="maxExprienceRequired"
                  type="number"
                  label="Max."
                  onChange={formik.handleChange}
                  value={formik.values.maxExprienceRequired}
                  fullWidth
                  error={
                    formik.touched.maxExprienceRequired &&
                    Boolean(formik.errors.maxExprienceRequired)
                  }
                  helperText={
                    formik.touched.maxExprienceRequired &&
                    formik.errors.maxExprienceRequired
                  }
                  sx={{ marginBottom: 3 }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name="title"
              label="Title *"
              onChange={formik.handleChange}
              value={formik.values.title}
              fullWidth
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              sx={{ marginBottom: 3 }}
            />
            <Typography sx={{ marginBottom: 1, textAlign: "center" }}>
              Job Description
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              data={jobPost.jobDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik.setFieldValue("jobDescription", data);
              }}
            />
            <Typography sx={{ margin: 1 }} variant="body2" color="error">
              {formik.errors.jobDescription}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="outlined" onClick={onCancel} sx={{ marginLeft: 2 }}>
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default JobPostForm;
