import { Grid } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Grid sx={{ marginTop: 8, marginX: "auto" }} maxWidth="lg">
      <Outlet />
    </Grid>
  );
};

export default AuthLayout;
