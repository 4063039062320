import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import BackgroundImage from "../../../../assets/images/hero.jpg";
import SearchComponent from "./SearchComponent";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <motion.div
      className="box"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <Grid
        sx={{
          // backgroundImage: `url(${BackgroundImage})`,
          // backgroundSize: "cover",
          // backgroundPosition: "top",
          // backgroundRepeat: "no-repeat",
          backgroundColor: "#021E70",
          align: "center",
          height: { xs: "235px", lg: "120px" },
          width: "auto",
        }}
        container
      >
        <Grid
          container
          maxWidth="xl"
          sx={{ marginX: "auto", marginY: 3, paddingX: 2 }}
        >
          {/* <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              color: "#263238",
              marginBottom: 6,
              display: { xs: "none", md: "flex" },
            }}
          >
            Connecting The Best Employees <br /> With The Best Employers
          </Typography> */}
          <Grid item xs="12">
            <Box
              sx={{
                bgcolor: "#ffffff",
                borderRadius: 2,
                paddingY: 2,
                paddingX: 3,
                alignItems: "center",
              }}
            >
              <SearchComponent />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default Banner;
