import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//import material ui
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Link,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useTheme } from "@mui/material/styles";

//project components
import CompanyLogo from "../../../assets/images/HeaderLogo.jpeg";
import config from "../../../config";
import { setUser } from "../../../store/userSlice";

//third party
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),
  middleName: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),
  lastName: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),
  dateOfBirth: yup
    .date()
    .min(new Date("01-01-1950"))
    .max(new Date())
    .required(),
  phoneNumber: yup
    .string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .max(10, "Max 10 digits are allowed"),
  city: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),
  currentPost: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),
  degreeName: yup
    .string()
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  instituteName: yup
    .string()
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  yearOfPassing: yup.number().required("Required"),
  aggrigate: yup.number("Enter aggrigate percentage").required("Required"),
  email: yup
    .string("Enter your email")
    .max(50, "Must be 50 characters or less ")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .max(50, "Must be 50 characters or less ")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match"),
    }),
});

const SignUp = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tandcAccepted, setTandcAccepted] = useState(false);
  const [openTermsDlg, setOpenTermsDlg] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      dateOfBirth: new Date().toISOString().split("T")[0],
      phoneNumber: "",
      city: "",
      currentPost: "",
      instituteName: "",
      degreeName: "",
      yearOfPassing: "",
      aggrigate: "",
      role: 1,
      password: "",
      confirmPassword: "",
      type: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      axios
        .post(
          config.serverURL + "/api/Users/CheckEmailExists?email=" + values.email
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data == false) {
              SignUp(values);
            } else {
              alert(
                "The email: " +
                  values.email +
                  "already exists, please sign in with this email instead."
              );
              setSubmitting(false);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          setSubmitting(false);
        });
    },
  });

  const SignUp = (values) => {
    console.log(values);
    axios
      .post(config.serverURL + "/api/Authentication/CandidateSignup", values)
      .then((response) => {
        if (response.status === 200) {
          let loginRequest = {
            emailAddress: values.email,
            password: values.password,
          };
          axios
            .post(config.serverURL + "/api/Authentication/Signin", loginRequest)
            .then((response) => {
              if (response.status === 200) {
                localStorage.setItem("accessToken", response.data.accessToken);
                dispatch(setUser(response.data));
                navigate("/");
              }
              setSubmitting(false);
            })
            .catch(function (error) {
              console.log(error);
              setSubmitting(false);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
      });
  };

  return (
    <Card elevation={4}>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            //bgcolor: "#e3f2fd",
            paddingX: 2,
            paddingY: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={CompanyLogo} alt="company-logo" width="80%" />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            padding: 4,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ marginBottom: 2, textAlign: "center" }}
            variant="h4"
            color="primary.dark"
          >
            Sign Up
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="First Name"
                  name="firstName"
                  type="text"
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                ></TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Middle Name"
                  name="middleName"
                  type="text"
                  fullWidth
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.middleName &&
                    Boolean(formik.errors.middleName)
                  }
                  helperText={
                    formik.touched.middleName && formik.errors.middleName
                  }
                ></TextField>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                ></TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="dateOfBirth"
                  type="date"
                  label="Date of Birth"
                  fullWidth
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.dateOfBirth &&
                    Boolean(formik.errors.dateOfBirth)
                  }
                  helperText={
                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  }
                ></TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Mobile Number"
                  name="phoneNumber"
                  type="text"
                  fullWidth
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                ></TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="City"
                  name="city"
                  type="text"
                  fullWidth
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                ></TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Current Post"
                  name="currentPost"
                  type="text"
                  fullWidth
                  value={formik.values.currentPost}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.currentPost &&
                    Boolean(formik.errors.currentPost)
                  }
                  helperText={
                    formik.touched.currentPost && formik.errors.currentPost
                  }
                ></TextField>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  name="degreeName"
                  label="Degree Name"
                  onChange={formik.handleChange}
                  value={formik.values.degreeName}
                  fullWidth
                  error={
                    formik.touched.degreeName &&
                    Boolean(formik.errors.degreeName)
                  }
                  helperText={
                    formik.touched.degreeName && formik.errors.degreeName
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="instituteName"
                  label="Institute Name"
                  onChange={formik.handleChange}
                  value={formik.values.instituteName}
                  fullWidth
                  error={
                    formik.touched.instituteName &&
                    Boolean(formik.errors.instituteName)
                  }
                  helperText={
                    formik.touched.instituteName && formik.errors.instituteName
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="yearOfPassing"
                  label="Year of Passing"
                  onChange={formik.handleChange}
                  value={formik.values.yearOfPassing}
                  fullWidth
                  error={
                    formik.touched.yearOfPassing &&
                    Boolean(formik.errors.yearOfPassing)
                  }
                  helperText={
                    formik.touched.yearOfPassing && formik.errors.yearOfPassing
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="aggrigate"
                  label="Aggrigate"
                  onChange={formik.handleChange}
                  value={formik.values.aggrigate}
                  fullWidth
                  error={
                    formik.touched.aggrigate && Boolean(formik.errors.aggrigate)
                  }
                  helperText={
                    formik.touched.aggrigate && formik.errors.aggrigate
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  label="Email Address"
                  name="email"
                  type="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                ></TextField>
              </Grid>
              <Grid item sm={12} xs={12} sx={{ mt: -3 }}>
                <Typography color={theme.palette.grey[600]} variant="body2">
                  This will be your username
                </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    label="Password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText error id="outlined-adornment-password">
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    name="confirmPassword"
                    id="outlined-adornment-confirm-password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    label="Confirm Password"
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <FormHelperText
                        error
                        id="outlined-adornment-confirm-password"
                      >
                        {formik.errors.confirmPassword}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{ display: "flex", direction: "row", alignItems: "center" }}
              >
                <Checkbox
                  checked={tandcAccepted}
                  onChange={(e) => {
                    setTandcAccepted(e.target.checked);
                  }}
                />
                <Typography sx={{ marginRight: 1 }}>Accept </Typography>
                <Link
                  underline="hover"
                  variant="h6"
                  rel="noopener noreferrer"
                  onClick={() => {
                    setOpenTermsDlg(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  Terms & Conditions
                </Link>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item sm={12} xs={12}>
                <LoadingButton
                  variant="contained"
                  disabled={!tandcAccepted}
                  type="submit"
                  fullWidth
                  loading={submitting}
                  endIcon={<HowToRegIcon />}
                  loadingPosition="end"
                  size="large"
                  color="secondary"
                >
                  Sign Up
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={openTermsDlg}
        onClose={() => {
          setOpenTermsDlg(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Terms & Conditions</DialogTitle>
        <DialogContent>List of terms & Conditions</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenTermsDlg(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SignUp;
