import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useReducer, useSelector } from "react-redux";

// routes
//import AdminRoutes from "./AdminRoutes";
//import WebsiteRoutes from "./WebsiteRoutes";
//import AuthRoutes from "./AuthRoutes";

import WebsiteLayout from "../layout/website-layout";
import AuthLayout from "../layout/auth-layout";

import LandingPage from "../pages/website/landing-page";
import Profile from "../pages/website/profile";
import About from "../pages/website/about-us/index.jsx";
import Contact from "../pages/website/contact/index.jsx";
import Jobposts from "../pages/website/jobpost-list/index.jsx";
import JobDetails from "../pages/website/job-details";
import Services from "../pages/website/services";

import Auth from "../pages/common/auth";

import Candidates from "../pages/admin/candidates";
import Employers from "../pages/admin/employers";
import EmployerDetails from "../pages/admin/employers/details";
import JobPosts from "../pages/admin/job-posts";
import Settings from "../pages/admin/settings";
import JobPostDetails from "../pages/admin/job-post-details";
import Interviews from "../pages/admin/interviews";

import SamplePage from "../pages/sample-page";
import ForgotPassword from "../pages/common/auth/ForgotPassword";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  const WebsiteRoutes = {
    path: "/",
    element: <WebsiteLayout />,
    children: [
      {
        path: "/",
        element:
          role === 4 ? <Navigate to="/admin/candidates" /> : <LandingPage />,
      },
      { path: "/about", element: <About /> },
      { path: "/jobs", element: <Jobposts /> },
      { path: "/jobdetails", element: <JobDetails /> },
      { path: "/contact", element: <Contact /> },
      {
        path: "/profile",
        element: role > 0 ? <Profile /> : <Navigate to="/" />,
      },
      { path: "/services", element: <Services /> },
    ],
  };

  const AuthRoutes = {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/auth", element: <Auth /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
    ],
  };

  const AdminRoutes = {
    path: "/admin",
    element: <WebsiteLayout />,
    children: [
      {
        path: "/admin/jobposts",
        element: role === 4 ? <JobPosts /> : <Navigate to="/" />,
      },
      {
        path: "/admin/jobpost",
        element: role === 4 ? <JobPostDetails /> : <Navigate to="/" />,
      },
      {
        path: "/admin/candidates",
        element: role === 4 ? <Candidates /> : <Navigate to="/" />,
      },
      {
        path: "/admin/employers",
        element: role === 4 ? <Employers /> : <Navigate to="/" />,
      },
      {
        path: "/admin/employer-details",
        element: role === 4 ? <EmployerDetails /> : <Navigate to="/" />,
      },
      {
        path: "/admin/settings",
        element: role === 4 ? <Settings /> : <Navigate to="/" />,
      },
      {
        path: "/admin/interviews",
        element: role === 4 ? <Interviews /> : <Navigate to="/" />,
      },
    ],
  };

  return useRoutes([AdminRoutes, WebsiteRoutes, AuthRoutes]);
}
