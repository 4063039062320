import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import SearchIcon from "@mui/icons-material/Search";
import IconLogout from "@mui/icons-material/Logout";
import IconSettings from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import CompanyLogo from "../../assets/images/HeaderLogo.jpeg";
import {
  ButtonGroup,
  InputAdornment,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Divider,
  ListItemButton,
  Stack,
  Fab,
  Badge,
  ButtonBase,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

//project imports
import websiteMenuItems from "./menus/websiteMenuItems";
import adminMenuItems from "./menus/adminMenuItems";
import { setUser } from "../../store/userSlice";
import { setCurrentPage } from "../../store/generalSlice";
import MenuDrawer from "./MenuDrawer";
import zIndex from "@mui/material/styles/zIndex";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import ChangePassword from "../../pages/common/auth/ChangePassword";
import config from "../../config";
import axios from "axios";

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const { currentPage } = useSelector((state) => state.generalReducer);
  const navigate = useNavigate();

  const [openChangePassword, setOpenChangePassword] = useState(false);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -4,
      top: 14,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: "10px 6px",
      color: theme.palette.white.main,
      fontWeight: 400,
    },
  }));

  const [iconColor, setIconColor] = useState();
  const [notifications, setNotifications] = useState();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
        accessToken: "",
      })
    );

    navigate("/");
  };

  const getNotifications = () => {
    axios
      .get(
        config.serverURL +
          "/api/Notifications/GetNotification?userId=" +
          userId +
          "&status=0"
      )
      .then((response) => {
        if (response.status === 200) {
          setNotifications(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setStatus = (id) => {
    axios
      .post(
        config.serverURL +
          "/api/Notifications/Update-NotoficationStatus?id=" +
          id +
          "&status=1"
      )
      .then((response) => {
        if (response.status === 200) {
          getNotifications();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppBar position="fixed" color="white">
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
          }}
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" }, cursor: "pointer" }}
            onClick={() => {
              if (role <= 1) {
                navigate("/");
              }
            }}
          >
            <img src={CompanyLogo} alt="company-logo" width="200px" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MenuDrawer />
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none", flexGrow: 1 },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={CompanyLogo} alt="company-logo" width="130px" />
          </Box>

          <Box display="flex">
            {/* nav manu */}
            <Box
              mr={4}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              {role === 1 || role === 0 ? (
                <Stack direction="row">
                  {websiteMenuItems.map((menu, index) => {
                    return (
                      <Button
                        key={index}
                        sx={{
                          borderBottom:
                            window.location.pathname == menu.path ? 2 : 0,
                          borderRadius: 0,
                          marginRight: 1,
                        }}
                        onClick={() => {
                          dispatch(setCurrentPage(index));
                          navigate(menu.path);
                        }}
                      >
                        {menu.title}
                      </Button>
                    );
                  })}
                </Stack>
              ) : (
                <Stack direction="row">
                  {adminMenuItems.map((menu, index) => {
                    return (
                      <Button
                        key={index}
                        sx={{
                          borderBottom: index === currentPage ? 2 : 0,
                          borderRadius: 0,
                          marginRight: 1,
                        }}
                        onClick={() => {
                          dispatch(setCurrentPage(index));
                          navigate(menu.path);
                        }}
                      >
                        {menu.title}
                      </Button>
                    );
                  })}
                </Stack>
              )}
            </Box>

            {/* Notifications manu */}
            {role > 0 && (
              <Box sx={{ marginRight: 3 }}>
                <Box onClick={handleOpenNotificationMenu}>
                  <StyledBadge
                    badgeContent={notifications?.count}
                    max={9}
                    color="secondary"
                    overlap="circular"
                  >
                    <ButtonBase
                      variant="outlined"
                      sx={{
                        background: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        transition: "all .2s ease-in-out",
                        padding: 1,
                        borderRadius: 2,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                          background: theme.palette.primary.dark,
                          color: theme.palette.primary.light,
                        },
                      }}
                    >
                      <NotificationsNoneIcon sx={{ margin: 0, padding: 0 }} />
                    </ButtonBase>
                  </StyledBadge>
                </Box>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-notification"
                  anchorEl={anchorElNotification}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNotification)}
                  onClose={handleCloseNotificationMenu}
                >
                  <Typography textAlign="center" m={1} color="primary">
                    Top 10 Notifications
                  </Typography>
                  <Divider />
                  {notifications?.notifications.length === 0 && (
                    <Box sx={{ width: 400, padding: 2 }}>
                      <Typography textAlign="center">
                        You don't have any new notifications
                      </Typography>
                    </Box>
                  )}
                  {notifications?.notifications.map((row, index) => (
                    <Box key={index} sx={{ width: 380 }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        sx={{ padding: 2 }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            color="secondary.dark"
                          >
                            {row.primaryMessage}
                          </Typography>
                          <Link
                            rel="noreferrer"
                            underline="hover"
                            variant="body2"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (row.navigationType === 0) {
                                navigate("/profile?ref=" + row.navigationId);
                              } else if (row.navigationType === 2) {
                                navigate(
                                  "/jobdetails?ref=" +
                                    row.navigationId +
                                    "&prePage=/jobs"
                                );
                              } else if (row.navigationType === 1) {
                                navigate(
                                  "/admin/jobpost?ref=" +
                                    row.navigationId +
                                    "&prePage=/admin/jobposts"
                                );
                              }
                              handleCloseNotificationMenu();
                            }}
                          >
                            {row.secondaryMessage}
                          </Link>
                        </Box>
                        <Tooltip title="Mark as read">
                          <IconButton
                            onClick={() => {
                              setStatus(row.id);
                            }}
                            size="small"
                          >
                            <MarkChatReadIcon
                              color={iconColor}
                              fontSize="10px"
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Divider />
                    </Box>
                  ))}
                </Menu>
              </Box>
            )}

            {/* Profile manu */}
            {role > 0 ? (
              <Box>
                <Tooltip title={"Hi, " + userName}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                      }}
                    >
                      <Typography color={theme.palette.common.white}>
                        {userName.charAt(0)}
                      </Typography>
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box sx={{ width: 230 }}>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar
                          sx={{
                            mr: 2,
                            backgroundColor: theme.palette.secondary.main,
                          }}
                        >
                          <Typography color={theme.palette.common.white}>
                            {userName.charAt(0)}
                          </Typography>
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={userName}
                        secondary={displayRole}
                        secondaryTypographyProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: 14,
                          },
                        }}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: 500,
                            color: theme.palette.secondary.dark,
                            fontSize: 16,
                          },
                        }}
                      ></ListItemText>
                    </ListItem>
                    <Divider sx={{ marginTop: 1 }} />
                    {role === 1 ? (
                      <ListItemButton
                        sx={{ borderRadius: `4px` }}
                        onClick={() => {
                          navigate("/profile");
                          handleCloseUserMenu();
                        }}
                      >
                        <ListItemIcon>
                          <IconSettings
                            stroke={1.5}
                            size="1.3rem"
                            color={theme.palette.grey[800]}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              color={theme.palette.grey[800]}
                            >
                              My Profile
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ) : (
                      <>
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          onClick={() => {
                            setOpenChangePassword(true);
                          }}
                        >
                          <ListItemIcon>
                            <LockOpenIcon
                              stroke={1.5}
                              size="1.3rem"
                              color={theme.palette.grey[800]}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                color={theme.palette.grey[800]}
                              >
                                Change Password
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        {/* <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <AlternateEmailIcon
                              stroke={1.5}
                              size="1.3rem"
                              color={theme.palette.grey[800]}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                color={theme.palette.grey[800]}
                              >
                                Change Email
                              </Typography>
                            }
                          /> 
                        </ListItemButton>*/}
                      </>
                    )}
                    <ListItemButton
                      sx={{ borderRadius: `4px` }}
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <IconLogout
                          stroke={1.5}
                          size="1.3rem"
                          color={theme.palette.grey[800]}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[800]}
                          >
                            Logout
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Box sx={{ pb: 2 }}>
                      <Divider />
                    </Box>
                  </Box>
                </Menu>
              </Box>
            ) : (
              <>
                {/* <Tooltip title="Click here to Sign In or Sign Up">
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      navigate("/auth");
                    }}
                  >
                    <LockOpenIcon />
                  </Fab>
                </Tooltip> */}
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  sx={{ paddingX: 1, color: theme.palette.white.main }}
                  onClick={() => {
                    navigate("/auth");
                  }}
                >
                  Signin / Signup
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openChangePassword}
        onClose={() => {
          setOpenChangePassword(false);
        }}
      >
        <DialogContent>
          <ChangePassword
            userId={userId}
            onCancel={() => {
              setOpenChangePassword(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};
export default Header;
