import React from "react";

import { Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@mui/material/styles";

import { Parallax } from "react-parallax";

import BG from "../../../../assets/images/taglinebg.jpg";
import CompanyLogo from "../../../../assets/images/logostroked.png";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";

const TagLine = () => {
  const theme = useTheme();
  return (
    <Grid>
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <motion.div
          className="box"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0 }}
        >
          <Parallax
            style={{ padding: 30 }}
            bgImage={BG}
            bgImageAlt="the cat"
            strength={200}
            width="100%"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={4}
              sx={{ marginY: 4 }}
            >
              <Typography
                color={theme.palette.common.white}
                variant="h4"
                textAlign="center"
              >
                Best Employees
              </Typography>

              <DoubleArrowIcon
                sx={{ color: theme.palette.common.white }}
                fontSize="large"
              />

              <img src={CompanyLogo} alt="company-logo" width="300px" />

              <DoubleArrowIcon
                sx={{ color: theme.palette.common.white }}
                fontSize="large"
              />

              <Typography
                color={theme.palette.common.white}
                variant="h4"
                textAlign="center"
              >
                Best Companies
              </Typography>
            </Stack>
          </Parallax>
        </motion.div>
      </Grid>
      <Grid container sx={{ display: { xs: "block", md: "none" } }}>
        <motion.div
          className="box"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0 }}
        >
          <Parallax
            style={{ padding: 30 }}
            bgImage={BG}
            bgImageAlt="the cat"
            strength={200}
            width="100%"
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{ marginY: 4 }}
            >
              <Typography
                color={theme.palette.common.white}
                variant="h4"
                textAlign="center"
              >
                Best Employees
              </Typography>

              <KeyboardDoubleArrowDownIcon
                sx={{ color: theme.palette.common.white }}
                fontSize="large"
              />

              <img src={CompanyLogo} alt="company-logo" width="300px" />

              <KeyboardDoubleArrowDownIcon
                sx={{ color: theme.palette.common.white }}
                fontSize="large"
              />

              <Typography
                color={theme.palette.common.white}
                variant="h4"
                textAlign="center"
              >
                Best Companies
              </Typography>
            </Stack>
          </Parallax>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default TagLine;
