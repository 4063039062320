import React, { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

//project imports
import config from "../../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const QualificationForm = (props) => {
  const { onSave, onCancel, selectedQualification } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      id: selectedQualification.id,
      candidateId: selectedQualification.candidateId,
      degreeName: selectedQualification.degreeName,
      instituteName: selectedQualification.instituteName,
      yearOfPassing: selectedQualification.yearOfPassing,
      aggrigate: selectedQualification.aggrigate,
    },
    validationSchema: Yup.object({
      degreeName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      instituteName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      yearOfPassing: Yup.number().required("Required"),
      aggrigate: Yup.number("Enter your email").required("Required"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      if (selectedQualification.id === 0) {
        axios
          .post(config.serverURL + "/api/CandidateQualifications", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .put(config.serverURL + "/api/CandidateQualifications", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });
  return (
    <>
      <Grid container>
        {selectedQualification.id === 0 ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />

            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Qualification
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Qualification Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="degreeName"
              label="Degree Name *"
              onChange={formik.handleChange}
              value={formik.values.degreeName}
              fullWidth
              error={
                formik.touched.degreeName && Boolean(formik.errors.degreeName)
              }
              helperText={formik.touched.degreeName && formik.errors.degreeName}
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="instituteName"
              label="Institute Name *"
              onChange={formik.handleChange}
              value={formik.values.instituteName}
              fullWidth
              error={
                formik.touched.instituteName &&
                Boolean(formik.errors.instituteName)
              }
              helperText={
                formik.touched.instituteName && formik.errors.instituteName
              }
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="yearOfPassing"
              label="Year of Passing *"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.yearOfPassing}
              fullWidth
              error={
                formik.touched.yearOfPassing &&
                Boolean(formik.errors.yearOfPassing)
              }
              helperText={
                formik.touched.yearOfPassing && formik.errors.yearOfPassing
              }
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="aggrigate"
              label="Aggregate/CGPA *"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.aggrigate}
              fullWidth
              error={
                formik.touched.aggrigate && Boolean(formik.errors.aggrigate)
              }
              helperText={formik.touched.aggrigate && formik.errors.aggrigate}
              sx={{ marginBottom: 3 }}
            />

            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default QualificationForm;
