import axios from "../api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setAccessToken } from "../store/userSlice";
import { setUser } from "../store/userSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
        accessToken: "",
      })
    );
    navigate("/");
  };

  const refresh = async (accessToken) => {
    if (
      accessToken === "" ||
      accessToken === undefined ||
      accessToken === null
    ) {
      logout();
    }
    console.log(accessToken);
    let request = {
      accessToken: accessToken,
    };
    axios
      .post("/api/Authentication/refresh", request)
      .then((response) => {
        console.log(response.data);
        dispatch(setAccessToken(response.data.accessToken));
        localStorage.setItem("accessToken", response.data.accessToken);
        return response.data.accessToken;
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data === "session expired") {
          logout();
        }
      });
  };
  return refresh;
};

export default useRefreshToken;
