import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

//import material ui
import {
  Grid,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
  Pagination,
  TablePagination,
  Fab,
  Stack,
  TextField,
  InputAdornment,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
} from "@mui/material";
import { Box } from "@mui/system";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

//project components
import config from "../../../config";
import JobPostForm from "./JobPostForm";
import { setJobPostFilterData } from "../../../store/generalSlice";

//third party
import axios from "axios";
import { useFormik } from "formik";
import JobPost from "../../../components/job-post/JobPost";

const JobPosts = () => {
  const navigate = useNavigate();
  const pageSize = 50;
  const dispatch = useDispatch();
  const [jobPosts, setJobPosts] = useState();
  const [selectedJobPost, setSelectedJobPost] = useState();
  const [openJobPostForm, setOpenJobPostForm] = useState(false);
  const { jobPostFilterData } = useSelector((state) => state.generalReducer);

  const getJobPosts = (filterData, skip) => {
    let minSalary = filterData.minSalary;
    let maxSalary = filterData.maxSalary;
    if (filterData.minSalary === "") {
      //formik.setFieldValue("minSalary", 0);
      minSalary = 0;
    }
    if (filterData.maxSalary === "") {
      //formik.setFieldValue("maxSalary", 0);
      maxSalary = 0;
    }

    let minExperience = filterData.minExperience;
    let maxExperience = filterData.maxExperience;
    if (
      filterData.minExperience === "" ||
      filterData.minExperience === undefined
    ) {
      //formik.setFieldValue("minSalary", 0);
      minExperience = 0;
    }
    if (
      filterData.maxExperience === "" ||
      filterData.minExperience === undefined
    ) {
      //formik.setFieldValue("maxSalary", 0);
      maxExperience = 0;
    }

    let tempFilterData = {
      ...filterData,
      skip: skip,
      minSalary: minSalary,
      maxSalary: maxSalary,
      minExperience: minExperience,
      maxExperience: maxExperience,
    };
    dispatch(setJobPostFilterData(tempFilterData));
    axios
      .post(config.serverURL + "/api/JobPosts/Search", tempFilterData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setJobPosts(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: jobPostFilterData,
    onSubmit: (values) => {
      if (formik) getJobPosts(values, 0);
    },
  });

  useEffect(() => {
    getJobPosts(formik.values, 0);
  }, [formik.values]);

  return (
    <Grid maxWidth="xl" sx={{ marginX: "auto", paddingY: 4, paddingX: 2 }}>
      <Card elevation={3}>
        <Grid
          sx={{
            background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="white.main">
              Job Posts
            </Typography>
            <Grid>
              <Tooltip title="Add New">
                <IconButton
                  color="white"
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    setSelectedJobPost({
                      id: 0,
                      employerId: 1,
                      jobTypeId: 1,
                      title: "",
                      jobDescription: "",
                      qualification: "",
                      location: "",
                      minSalary: 0,
                      maxSalary: 0,
                      noOfVaccancy: 1,
                      postDate: new Date().toISOString().split("T")[0],
                      lastDate: new Date().toISOString().split("T")[0],
                      minExprienceRequired: 0,
                      maxExprienceRequired: 0,
                    });
                    setOpenJobPostForm(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            spacing={3}
            maxWidth="xl"
            sx={{ marginTop: 1, marginBottom: 3, paddingX: 2 }}
          >
            <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <Card variant="outlined">
                  <CardHeader title="Filters"></CardHeader>
                  <Divider />
                  <CardContent>
                    <Stack spacing={3}>
                      <TextField
                        name="skill"
                        label="Skills"
                        fullWidth
                        value={formik.values.skill}
                        onChange={formik.handleChange}
                      ></TextField>
                      <TextField
                        name="location"
                        label="Location"
                        fullWidth
                        value={formik.values.location}
                        onChange={formik.handleChange}
                      ></TextField>
                      <TextField
                        name="companyName"
                        label="Employer Name"
                        fullWidth
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                      ></TextField>
                      <FormControl sx={{ marginBottom: 3 }} fullWidth>
                        <InputLabel>Job Type</InputLabel>
                        <Select
                          name="jobType"
                          label="Job Type"
                          onChange={formik.handleChange}
                          value={formik.values.jobType}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          <MenuItem value={1}>Part Time</MenuItem>
                          <MenuItem value={2}>Full Time</MenuItem>
                          <MenuItem value={3}>Contract Basis</MenuItem>
                        </Select>
                      </FormControl>
                      <Typography>Monthly Salary</Typography>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          type="number"
                          name="minSalary"
                          label="Min."
                          fullWidth
                          value={formik.values.minSalary}
                          onChange={formik.handleChange}
                        ></TextField>
                        <TextField
                          type="number"
                          name="maxSalary"
                          label="Max."
                          fullWidth
                          value={formik.values.maxSalary}
                          onChange={formik.handleChange}
                        ></TextField>
                      </Stack>
                      <Typography>Experience Required</Typography>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          type="number"
                          name="minExperience"
                          label="Min."
                          fullWidth
                          value={formik.values.minExperience}
                          onChange={formik.handleChange}
                        ></TextField>
                        <TextField
                          type="number"
                          name="maxExperience"
                          label="Max."
                          fullWidth
                          value={formik.values.maxExperience}
                          onChange={formik.handleChange}
                        ></TextField>
                      </Stack>
                    </Stack>
                  </CardContent>
                  <Divider />
                  <CardActions sx={{ paddingY: 3, justifyContent: "center" }}>
                    {/* <Button variant="contained" type="submit">
                    Apply
                  </Button> */}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        let tempFilterData = {
                          companyName: "",
                          jobType: 0,
                          position: "",
                          location: "",
                          skill: "",
                          minSalary: 0,
                          maxSalary: 0,
                          minExperience: 0,
                          maxExperience: 0,
                          status: 0,
                          skip: 0,
                          take: pageSize,
                        };
                        formik.setValues(tempFilterData);
                        dispatch(setJobPostFilterData(tempFilterData));
                        formik.handleSubmit();
                      }}
                    >
                      Clear
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </Grid>
            <Grid item lg={8} xl={8} md={8} sm={12} xs={12}>
              <Card
                variant="outlined"
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  display: "flex",
                  direction: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {jobPosts != undefined ? (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>Total: {jobPosts.count}</Typography>
                    <Pagination
                      count={Math.ceil(jobPosts.count / pageSize)}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                      color="primary"
                      onChange={(event, page) => {
                        getJobPosts(jobPostFilterData, (page - 1) * pageSize);
                      }}
                    />
                  </Stack>
                ) : null}
              </Card>
              {jobPosts?.jobPosts.map((row) => (
                <Box key={row.id} sx={{ marginBottom: 2 }}>
                  <JobPost jobPost={row} type={2} />
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openJobPostForm}
        onClose={() => {
          setOpenJobPostForm(false);
        }}
      >
        <DialogContent>
          <JobPostForm
            jobPost={selectedJobPost}
            onSave={() => {
              getJobPosts(0);
              setOpenJobPostForm(false);
            }}
            onCancel={() => {
              setOpenJobPostForm(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default JobPosts;
