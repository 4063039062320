import { useEffect, useState } from "react";

//import material
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Paper,
  CardHeader,
  Typography,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  Stack,
  Box,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import axios from "axios";
import config from "../../../../config";

//project components
import QualificationForm from "../qualification/QualificationForm";

const Qualification = (props) => {
  const { qualifications, onSave, candidateId } = props;
  //const [qualifications, setQualifications] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [selectedQualification, setSelectedQualification] = useState({
    id: 0,
    candidateId: 0,
    degreeName: "",
    instituteName: "",
    yearOfPassing: 0,
    aggrigate: 0,
  });

  const handleAdd = () => {
    setSelectedQualification({
      id: 0,
      candidateId: candidateId,
      degreeName: "",
      instituteName: "",
      yearOfPassing: "",
      aggrigate: "",
    });
    setOpenAdd(true);
  };

  const handleDelete = () => {
    axios
      .delete(
        config.serverURL +
          "/api/CandidateQualifications/" +
          selectedQualification.id
      )
      .then((response) => {
        onSave();
        setOpenDelete(false);
      })
      .catch((error) => {
        console.log(error);
        setOpenDelete(false);
      });
  };
  const handleSave = () => {
    onSave();
    setOpenAdd(false);
  };
  const handleCancel = () => {
    setOpenAdd(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  return (
    <Card elevation={3}>
      <Grid
        sx={{
          background: "linear-gradient(to right,#4a7dcf 0%, #2a569f 100%)",
          paddingX: 2,
          paddingY: 0.5,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color: "#ffffff",
              }}
            >
              Educational Qualification
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleAdd}>
              <AddIcon sx={{ color: "#ffffff" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
          }}
        >
          {qualifications.map((qualification, index) => {
            return (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  margin: 1,
                  paddingX: 1,
                  paddingY: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <Stack spacing={1}>
                  <Typography>
                    <b>Degree:</b> {qualification.degreeName}
                  </Typography>
                  <Typography>
                    <b>Institute:</b> {qualification.instituteName}
                  </Typography>
                  <Typography>
                    <b>Year of Passing:</b> {qualification.yearOfPassing}
                  </Typography>
                  <Typography>
                    <b>Aggregate/CGPA:</b> {qualification.aggrigate}
                  </Typography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <IconButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setSelectedQualification(qualification);
                      setOpenAdd(true);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedQualification(qualification);
                      setOpenDelete(true);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                  <Typography>{index + 1}</Typography>
                </Stack>
              </Card>
            );
          })}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell align="left">Degree</TableCell>
                  <TableCell align="left">Institute</TableCell>
                  <TableCell align="left">Year of Passing</TableCell>
                  <TableCell align="left">Aggregate</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {qualifications.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.degreeName}</TableCell>
                    <TableCell align="left">{row.instituteName}</TableCell>
                    <TableCell align="left">{row.yearOfPassing}</TableCell>
                    <TableCell align="left">{row.aggrigate}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setSelectedQualification(row);
                          setOpenAdd(true);
                        }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => {
                          setSelectedQualification(row);
                          setOpenDelete(true);
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>

      <Dialog open={openAdd} onClose={handleCancel} fullWidth>
        <DialogContent>
          <QualificationForm
            onSave={handleSave}
            onCancel={handleCancel}
            selectedQualification={selectedQualification}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Qualification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the qualification of{" "}
            <span style={{ color: "#E90000" }}>
              {selectedQualification.degreeName}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Qualification;
