const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  basename: "/",
  defaultPath: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 4,
  //serverURL: "https://localhost:44313",
  //serverURL: "http://backend.optimajobs.in",
  serverURL: "http://backendtest.optimajobs.in",
};

export default config;
