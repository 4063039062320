import React, { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SaveIcon from "@mui/icons-material/Save";

//project imports
import config from "../../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const WorkExperienceForm = (props) => {
  const { onSave, onCancel, selectedWorkExperience } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isCurrentChecked, setIsCurrentChecked] = useState(
    selectedWorkExperience?.isCurrent
  );

  const formik = useFormik({
    initialValues: {
      id: selectedWorkExperience.id,
      candidateId: selectedWorkExperience.candidateId,
      companyName: selectedWorkExperience.companyName,
      position: selectedWorkExperience.position,
      salary: selectedWorkExperience.salary,
      fromDate: new Date(selectedWorkExperience.fromDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      toDate: new Date(selectedWorkExperience.toDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      isCurrent: selectedWorkExperience.isCurrent,
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      position: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      salary: Yup.number().required("Required"),
      fromDate: Yup.date("must be valid date")
        .min(new Date("01-01-1950"))
        .max(new Date())
        .required("Required"),
      toDate: Yup.date("must be valid date")
        .min(new Date("01-01-1950"))
        .max(new Date())
        .required("Required"),
    }),
    onSubmit: (values) => {
      values.isCurrent = isCurrentChecked;
      setLoading(true);
      if (selectedWorkExperience.id === 0) {
        axios
          .post(config.serverURL + "/api/CandidateWorkExpriences", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .put(config.serverURL + "/api/CandidateWorkExpriences", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Grid container>
        {selectedWorkExperience.id === 0 ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />

            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Work Experience
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Experience Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="companyName"
              label="Company Name *"
              onChange={formik.handleChange}
              value={formik.values.companyName}
              fullWidth
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="position"
              label="Position *"
              onChange={formik.handleChange}
              value={formik.values.position}
              fullWidth
              error={formik.touched.position && Boolean(formik.errors.position)}
              helperText={formik.touched.position && formik.errors.position}
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="salary"
              label="Salary / Month *"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.salary}
              fullWidth
              error={formik.touched.salary && Boolean(formik.errors.salary)}
              helperText={formik.touched.salary && formik.errors.salary}
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="fromDate"
              type="date"
              label="From Date *"
              onChange={formik.handleChange}
              value={formik.values.fromDate}
              fullWidth
              error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
              helperText={formik.touched.fromDate && formik.errors.fromDate}
              sx={{ marginBottom: 3 }}
            />

            <FormControlLabel
              sx={{ marginBottom: 3 }}
              label="Till Today"
              control={
                <Checkbox
                  checked={isCurrentChecked}
                  onChange={(e) => {
                    setIsCurrentChecked(e.target.checked);
                  }}
                />
              }
            />

            {!isCurrentChecked && (
              <TextField
                name="toDate"
                type="date"
                label="To Date *"
                onChange={formik.handleChange}
                value={formik.values.toDate}
                fullWidth
                error={formik.touched.toDate && Boolean(formik.errors.toDate)}
                helperText={formik.touched.toDate && formik.errors.toDate}
                sx={{ marginBottom: 3 }}
              />
            )}

            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkExperienceForm;
