import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//material ui components
import {
  Alert,
  Button,
  Card,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

//project components
import GeneralInfo from "./general-info";
import SidePanel from "./side-panel";
import Qualification from "./qualification";
import WorkExperience from "./work-experience";
import config from "../../../config";
import AppliedJobs from "./applied-jobs";

//third party
import axios from "axios";

const Profile = () => {
  const [candidate, setCandidate] = useState();
  const navigate = useNavigate();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const { userId, profileId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const [selectedPage, setSelectedPage] = useState(0);
  const [candidateComments, setCandidateComments] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Success");
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error");

  useEffect(() => {
    getUserDetails();
  }, [searchParams.get("ref")]);

  const getUserDetails = () => {
    let id = 1;
    if (role === 3 || role === 4) {
      id = searchParams.get("ref");
      if (id === null) {
        navigate("/");
        return;
      }
    } else if (role === 1 || role === 2) {
      id = profileId;
    } else {
      navigate("/");
      return;
    }
    axios
      .get(config.serverURL + "/api/Candidates/" + id)
      .then((response) => {
        setCandidate(response.data);
        setCandidateComments(response.data.comments);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    getUserDetails();
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  return (
    <Grid container maxWidth="xl" sx={{ marginX: "auto", paddingY: 3 }}>
      {candidate != undefined ? (
        <Grid container spacing={2} sx={{ padding: 1 }}>
          <Grid item xs={12} md={3}>
            <SidePanel
              candidate={candidate}
              onSave={getUserDetails}
              setSelectedPage={setSelectedPage}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            {selectedPage === 0 ? (
              <Stack direction="column" spacing={2}>
                <GeneralInfo candidate={candidate} onSave={handleSave} />
                {role === 4 && (
                  <Card elevation={4} sx={{ padding: 2 }}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6">Comments</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        value={candidateComments}
                        onChange={(e) => {
                          setCandidateComments(e.target.value);
                        }}
                      ></TextField>
                      <Button
                        variant="contained"
                        sx={{ width: 26 }}
                        onClick={() => {
                          axios
                            .post(
                              config.serverURL +
                                "/api/Candidates/UpdateComments?comment=" +
                                candidateComments +
                                "&candidateId=" +
                                candidate.id
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                if (response.data === "Success") {
                                  getUserDetails();
                                  setSuccessMsg(
                                    "Comments updates successfully!"
                                  );
                                  setSuccessOpen(true);
                                } else {
                                  setErrorMsg("Error in updating comments.");
                                  setErrorOpen(true);
                                }
                              } else {
                                console.log("Error");
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Card>
                )}
                <Qualification
                  qualifications={candidate.candidateQualifications}
                  candidateId={candidate.id}
                  onSave={handleSave}
                />
                <WorkExperience
                  workExperiences={candidate.candidateWorkExpriences}
                  candidateId={candidate.id}
                  onSave={handleSave}
                />
              </Stack>
            ) : (
              <AppliedJobs candidateId={candidate.id} />
            )}
          </Grid>
        </Grid>
      ) : null}

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "#009933",
            color: theme.palette.white.main,
          }}
          variant="filled"
        >
          {successMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Profile;
