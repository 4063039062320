import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import material ui
import {
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import SendIcon from "@mui/icons-material/Send";

//import project components
import CompanyLogo from "../../../assets/images/HeaderLogo.jpeg";
import config from "../../../config";
import { setUser } from "../../../store/userSlice";

//import third party
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = useState("");
  const [otp, setOtp] = useState("");

  const [sendingOtp, setSendingOtp] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [step, setStep] = useState(0);
  const [countDownTimer, setCountDownTimer] = useState("3:00");
  const [startCounter, setStartCounter] = useState(false);

  useEffect(() => {
    if (startCounter) {
      let tempResendDateTime = new Date().getTime() + 0.2 * 60000;

      const interval = setInterval(() => {
        var now = new Date().getTime();

        var distance = tempResendDateTime - now;

        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountDownTimer(`${minutes}:${seconds}`);

        // If the count down is finished, write some text
        if (distance < 0) {
          setCountDownTimer("");
          setStartCounter(false);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startCounter]);

  const sendOtp = () => {
    setSendingOtp(true);
    axios
      .post(
        config.serverURL +
          "/api/Authentication/Send-Password-Reset-Otp?email=" +
          emailAddress
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "success") {
            setStep(1);
            setSendingOtp(false);
            setStartCounter(true);
          } else {
            setSendingOtp(false);
            alert(response.data);
          }
        }
        setOtp("");
      })
      .catch((error) => {
        setSendingOtp(false);
      });
  };

  return (
    <Card elevation={4}>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            //bgcolor: "#e3f2fd",
            paddingX: 2,
            paddingY: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={CompanyLogo} alt="company-logo" width="80%" />
        </Grid>

        <Grid
          item
          sm={6}
          sx={{
            padding: 4,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ marginBottom: 3, textAlign: "center" }}
            variant="h4"
            color="primary.dark"
          >
            Password Reset
          </Typography>
          <Divider sx={{ marginY: 3 }} />
          <Stack spacing={3}>
            {step === 0 && (
              <>
                <Typography>Enter you registered email address</Typography>
                <TextField
                  type="email"
                  placeholder="email address"
                  values={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                ></TextField>
                <LoadingButton
                  variant="contained"
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  loading={sendingOtp}
                  onClick={() => {
                    sendOtp();
                    setStartCounter(false);
                  }}
                >
                  Send OTP
                </LoadingButton>
                <Divider sx={{ marginY: 3 }} />
              </>
            )}
            {step === 1 && (
              <>
                <Typography>Enter OTP received on registered email</Typography>
                <TextField
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  type="password"
                ></TextField>
                <Box>
                  <Typography textAlign="center">{countDownTimer}</Typography>
                  <LoadingButton
                    fullWidth
                    disabled={startCounter}
                    //endIcon={<SendIcon />}
                    loadingPosition="end"
                    loading={sendingOtp}
                    onClick={() => {
                      sendOtp();
                      setStartCounter(false);
                    }}
                  >
                    Resend OTP
                  </LoadingButton>
                </Box>
                <LoadingButton
                  variant="contained"
                  endIcon={<LoginIcon />}
                  loadingPosition="end"
                  loading={resettingPassword}
                  onClick={() => {
                    setResettingPassword(true);
                    if (otp === "") {
                      alert("Please enter otp");
                    }
                    axios
                      .post(
                        config.serverURL +
                          `/api/Authentication/Verify-Otp?otp=${otp}&email=${emailAddress}&isForgotPassword=${true}`
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          if (response.data === "success") {
                            setStep(2);
                            setResettingPassword(false);
                          } else {
                            setResettingPassword(false);
                            alert(response.data);
                          }
                        }
                      })
                      .catch((error) => {
                        setResettingPassword(false);
                      });
                  }}
                >
                  Submit
                </LoadingButton>
                <Divider sx={{ marginY: 3 }} />{" "}
                <Button
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  Change Email Address
                </Button>
              </>
            )}

            {step === 2 && (
              <>
                <Typography>
                  We have sent temperory password on you email address. Please
                  login with that password and change your password immediately
                  in profile section.
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/auth");
                  }}
                >
                  Sign In
                </Button>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ForgotPassword;
