import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//project components
import config from "../../../../config";

//third party
import axios from "axios";
import { Box, Card, Typography } from "@mui/material";
import { Grid } from "swiper";
import JobPost from "../../../../components/job-post/JobPost";

const AppliedJobs = (props) => {
  const { candidateId } = props;
  const [jobApplications, setJobApplications] = useState();

  const getJobApplications = () => {
    axios
      .get(
        config.serverURL +
          "/api/JobApplications/GetByCandidateId/" +
          candidateId
      )
      .then((response) => {
        setJobApplications(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getJobApplications();
  }, []);

  return (
    <Card sx={{ padding: 2 }} elevation={3}>
      <Typography sx={{ marginBottom: 2 }} variant="h6" color="primary">
        My Job Applications
      </Typography>
      {jobApplications?.length > 0 ? (
        <Box>
          {jobApplications?.map((row) => (
            <Box key={row.id} sx={{ marginBottom: 2 }}>
              <JobPost jobPost={row.jobPost} type={1} />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography textAlign="center" sx={{ margin: 5 }}>
          You have not applied to any job posts yet.
        </Typography>
      )}
    </Card>
  );
};

export default AppliedJobs;
