import { useState } from "react";

//material ui imports
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

//project imports
import config from "../../../../config";

//third party imports
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const GeneralInfoForm = (props) => {
  const { onSave, onCancel, candidate } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const genderOptions = [
    { value: "---", label: "---" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const stateOptions = [
    { value: "---", label: "---" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Goa", label: "Goa" },
    { value: "Karnataka", label: "Karnataka" },
  ];
  const maritialOptions = [
    { value: "---", label: "---" },
    { value: "Married", label: "Married" },
    { value: "Unmarried", label: "Unmarried" },
  ];

  const formik = useFormik({
    initialValues: {
      id: candidate.id,
      firstName: candidate.firstName,
      middleName: candidate.middleName,
      lastName: candidate.lastName,
      email: candidate.email,
      dateOfBirth: new Date(candidate.dateOfBirth.toString() + "Z")
        .toISOString()
        .split("T")[0],
      primaryMobileNumber: candidate.primaryMobileNumber,
      alternateMobileNumber: candidate.alternateMobileNumber,
      gender: candidate.gender,
      addressLine1: candidate.addressLine1,
      addressLine2: candidate.addressLine2,
      city: candidate.city,
      taluka: candidate.taluka,
      district: candidate.district,
      state: candidate.state,
      marritalStatus: candidate.marritalStatus,
      skills: candidate.skills,
      experienceMonths: candidate.experienceMonths,
      experienceYears: candidate.experienceYears,
      existingSalary: candidate.existingSalary,
      currentPost: candidate.currentPost,
      type: 0,
      photo: candidate.photo,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      middleName: Yup.string().max(20, "Must be 20 characters or less"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      email: Yup.string("Enter your email")
        .max(50, "Must be 50 characters or less ")
        .email("Enter a valid email")
        .required("Email is required"),
      dateOfBirth: Yup.date("must be valid date")
        .min(new Date("01-01-1900"), "Min date allowed is 01-01-1900")
        .max(new Date(), "Future date is not allowed")
        .required("Required"),
      primaryMobileNumber: Yup.string()
        .required("Required")
        .max(10, "Mobile no. must have 10 digits")
        .min(10, "Mobile no. must have 10 digits")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid. Only numbers are allowed"
        ),
      alternateMobileNumber: Yup.string()
        .max(10, "Mobile no. must have 10 digits")
        .min(10, "Mobile no. must have 10 digits")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid. Only numbers are allowed"
        ),
      addressLine1: Yup.string().max(100, "Must be 100 characters or less"),
      addressLine2: Yup.string().max(100, "Must be 100 characters or less"),
      skills: Yup.string().max(150, "Must be 150 characters or less"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      if (candidate.id != null) {
        axios
          .put(config.serverURL + "/api/Candidates", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        axios
          .post(config.serverURL + "/api/Candidates", values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  return (
    <>
      <Grid container>
        {candidate.id === null ? (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />

            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Add New Candidate
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ marginX: "auto", alignItems: "center" }}>
            <AccountBalanceIcon />
            <Typography
              color={theme.palette.primary.dark}
              variant="h6"
              textAlign="center"
              sx={{ marginLeft: 2 }}
            >
              Edit Candidate Details
            </Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              name="firstName"
              label="First Name *"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              fullWidth
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="middleName"
              label="Middle Name"
              onChange={formik.handleChange}
              value={formik.values.middleName}
              fullWidth
              error={
                formik.touched.middleName && Boolean(formik.errors.middleName)
              }
              helperText={formik.touched.middleName && formik.errors.middleName}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="lastName"
              label="Last Name *"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              fullWidth
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="email"
              label="Email *"
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="dateOfBirth"
              type="date"
              label="Date of Birth"
              onChange={formik.handleChange}
              value={formik.values.dateOfBirth}
              fullWidth
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              helperText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="primaryMobileNumber"
              label="Primary Mobile No. *"
              onChange={formik.handleChange}
              value={formik.values.primaryMobileNumber}
              fullWidth
              error={
                formik.touched.primaryMobileNumber &&
                Boolean(formik.errors.primaryMobileNumber)
              }
              helperText={
                formik.touched.primaryMobileNumber &&
                formik.errors.primaryMobileNumber
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="alternateMobileNumber"
              label="Alternate Mobile No."
              onChange={formik.handleChange}
              value={formik.values.alternateMobileNumber}
              fullWidth
              error={
                formik.touched.alternateMobileNumber &&
                Boolean(formik.errors.alternateMobileNumber)
              }
              helperText={
                formik.touched.alternateMobileNumber &&
                formik.errors.alternateMobileNumber
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ marginBottom: 3 }} fullWidth>
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                label="Gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                {genderOptions.map((options) => (
                  <MenuItem value={options.value}>{options.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ marginBottom: 3 }} fullWidth>
              <InputLabel>Maritial Status</InputLabel>
              <Select
                name="marritalStatus"
                label="Maritial Status"
                onChange={formik.handleChange}
                value={formik.values.marritalStatus}
                error={
                  formik.touched.marritalStatus &&
                  Boolean(formik.errors.marritalStatus)
                }
                helperText={
                  formik.touched.marritalStatus && formik.errors.marritalStatus
                }
              >
                {maritialOptions.map((options) => (
                  <MenuItem value={options.value}>{options.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              name="addressLine1"
              label="Address Line 1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1}
              fullWidth
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
              helperText={
                formik.touched.addressLine1 && formik.errors.addressLine1
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="addressLine2"
              label="Address Line 2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
              fullWidth
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
              helperText={
                formik.touched.addressLine2 && formik.errors.addressLine2
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ marginBottom: 3 }} fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                name="state"
                label="State"
                onChange={formik.handleChange}
                value={formik.values.state}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                {stateOptions.map((options) => (
                  <MenuItem value={options.value}>{options.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="district"
              label="District"
              onChange={formik.handleChange}
              value={formik.values.district}
              fullWidth
              error={formik.touched.city && Boolean(formik.errors.district)}
              helperText={formik.touched.district && formik.errors.district}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="taluka"
              label="Taluka"
              onChange={formik.handleChange}
              value={formik.values.taluka}
              fullWidth
              error={formik.touched.city && Boolean(formik.errors.taluka)}
              helperText={formik.touched.taluka && formik.errors.taluka}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="city"
              label="City/Town"
              onChange={formik.handleChange}
              value={formik.values.city}
              fullWidth
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              name="skills"
              label="Skills"
              onChange={formik.handleChange}
              value={formik.values.skills}
              fullWidth
              error={formik.touched.skills && Boolean(formik.errors.skills)}
              helperText={formik.touched.skills && formik.errors.skills}
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              name="experienceYears"
              type="number"
              label="Experience (Years)"
              onChange={formik.handleChange}
              value={formik.values.experienceYears}
              fullWidth
              error={
                formik.touched.experienceYears &&
                Boolean(formik.errors.experienceYears)
              }
              helperText={
                formik.touched.experienceYears && formik.errors.experienceYears
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              name="experienceMonths"
              type="number"
              label="Experience (Months)"
              onChange={formik.handleChange}
              value={formik.values.experienceMonths}
              fullWidth
              error={
                formik.touched.experienceMonths &&
                Boolean(formik.errors.experienceMonths)
              }
              helperText={
                formik.touched.experienceMonths &&
                formik.errors.experienceMonths
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="existingSalary"
              type="number"
              label="Existing Salary / Month"
              onChange={formik.handleChange}
              value={formik.values.existingSalary}
              fullWidth
              error={
                formik.touched.existingSalary &&
                Boolean(formik.errors.existingSalary)
              }
              helperText={
                formik.touched.existingSalary && formik.errors.existingSalary
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="currentPost"
              type="text"
              label="Current Post"
              onChange={formik.handleChange}
              value={formik.values.currentPost}
              fullWidth
              error={
                formik.touched.currentPost && Boolean(formik.errors.currentPost)
              }
              helperText={
                formik.touched.currentPost && formik.errors.currentPost
              }
              sx={{ marginBottom: 3 }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="end"
            endIcon={<SaveIcon />}
          >
            Submit
          </LoadingButton>
          <Button variant="outlined" onClick={onCancel} sx={{ marginLeft: 2 }}>
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default GeneralInfoForm;
