import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import material ui
import {
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";

//import project components
import CompanyLogo from "../../../assets/images/HeaderLogo.jpeg";
import config from "../../../config";
import { setUser } from "../../../store/userSlice";

//import third party
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

const validationSchema = yup.object({
  emailAddress: yup
    .string("Enter your email")
    .max(50, "Must be 50 characters or less ")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .required("Password is required"),
});

const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      emailAddress: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors, setStatus }) => {
      let loginRequest = {
        emailAddress: values.emailAddress,
        password: values.password,
      };
      setSubmitting(true);
      axios
        .post(config.serverURL + "/api/Authentication/Signin", loginRequest)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.message === "success") {
              localStorage.setItem("accessToken", response.data.accessToken);
              dispatch(setUser(response.data));
              navigate("/");
            } else {
              setErrors({ submit: response.data });
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        })
        .catch(function (error) {
          setErrors({ submit: "Internal server error, please try again." });
          setStatus({ success: false });
          setSubmitting(false);
        });
    },
  });
  return (
    <Card elevation={4}>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            //bgcolor: "#e3f2fd",
            paddingX: 2,
            paddingY: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={CompanyLogo} alt="company-logo" width="80%" />
        </Grid>

        <Grid
          item
          sm={6}
          sx={{
            padding: 4,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ marginBottom: 3, textAlign: "center" }}
            variant="h4"
            color="primary.dark"
          >
            Sign In
          </Typography>
          <Divider sx={{ marginY: 3 }} />
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                label="Email Address"
                name="emailAddress"
                type="email"
                fullWidth
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                error={
                  formik.touched.emailAddress &&
                  Boolean(formik.errors.emailAddress)
                }
                helperText={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
              ></TextField>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  label="Password"
                />
                {formik.touched.password && formik.errors.password && (
                  <FormHelperText error id="outlined-adornment-password">
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              {formik.errors.submit && (
                <Typography color="error">{formik.errors.submit}</Typography>
              )}
              <Button
                size="small"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot Password?
              </Button>
              <Grid item sm={12} xs={12}>
                <Divider />
              </Grid>
              <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                loading={submitting}
                loadingPosition="end"
                endIcon={<LoginIcon />}
                color="secondary"
              >
                Sign In
              </LoadingButton>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SignIn;
